import {
  Maybe,
  ParameterEnum,
  SubTemplate,
  SubTemplateConditionalUseConditionValue,
  SubTemplateParameter,
} from '../../../../api/generated';
import ValueOrPlaceholder from '../../../../components/ValueOrPlaceholder';

type Props = {
  subTemplate: SubTemplate;
};

function DetailsTab(props: Props) {
  return (
    <>
      <div className="entry-detail">
        <div className="field">
          <label htmlFor="name">Name</label>
          <div>
            <ValueOrPlaceholder value={props.subTemplate.name} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="description">Description</label>
          <div>
            <ValueOrPlaceholder value={props.subTemplate.description} />
          </div>
        </div>
        <div className="field">
          <label htmlFor="parameters">Selected parameters</label>
          <div>
            {props.subTemplate.subTemplateParameters ? (
              props.subTemplate.subTemplateParameters.map((eP, index) => {
                return <div key={`parameter-${index}`}>{eP?.parameter?.name}</div>;
              })
            ) : (
              <ValueOrPlaceholder value="-" />
            )}
          </div>
        </div>
        <div className="field">
          <label htmlFor="decisions">Decisions</label>
          <div>
            {props.subTemplate.subTemplateParameters ? (
              props.subTemplate.subTemplateParameters
                .filter((eP) => eP?.isDecision || eP?.parameter?.name.startsWith('Sys'))
                .map((eP, index) => {
                  return <div key={`decision-${index}`}>{eP?.parameter?.name}</div>;
                })
            ) : (
              <ValueOrPlaceholder value="-" />
            )}
          </div>
        </div>
        <div className="field">
          <label htmlFor="conditionalUse">Conditional Use</label>
          {props.subTemplate.conditionalUse?.conditions && props.subTemplate.conditionalUse?.conditions.length > 0 ? (
            props.subTemplate.conditionalUse?.conditions.map((item) => {
              let param = props.subTemplate.subTemplateParameters?.find((p) => p?.parameterId === item?.parameterId);
              return (
                <div key={item?.parameterId}>{param && item?.value && getConditionSentence(param, item.value)}</div>
              );
            })
          ) : (
            <div>
              <ValueOrPlaceholder value={null} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function getConditionSentence(
  param: SubTemplateParameter,
  value: SubTemplateConditionalUseConditionValue,
): string | undefined {
  const { bool, intFrom, intTo, doubleFrom, doubleTo, dateFrom, dateTo, enums, string } = value;
  const paramName = param.parameter?.name;
  const enumItems = param.parameter?.enumItems;

  if (bool !== null && bool !== undefined) {
    return `When '${paramName}' is '${bool ? 'True' : 'False'}'.`;
  } else if (intFrom != null && intTo != null) {
    return `When '${paramName}' is between ${intFrom} and ${intTo}.`;
  } else if (intFrom != null) {
    return `When '${paramName}' is higher than ${intFrom}.`;
  } else if (intTo != null) {
    return `When '${paramName}' is lower than ${intTo}.`;
  } else if (doubleFrom != null && doubleTo != null) {
    return `When '${paramName}' is between ${doubleFrom} and ${doubleTo}.`;
  } else if (doubleFrom != null) {
    return `When '${paramName}' is higher than ${doubleFrom}.`;
  } else if (doubleTo != null) {
    return `When '${paramName}' is lower than ${doubleTo}.`;
  } else if (dateFrom != null && dateTo != null) {
    return `When '${paramName}' is between ${dateFrom} and ${dateTo}.`;
  } else if (dateFrom != null) {
    return `When '${paramName}' is after ${dateFrom}.`;
  } else if (dateTo != null) {
    return `When '${paramName}' is before ${dateTo}.`;
  } else if (enums != null && enums.length > 0) {
    const enumValues = enums
      .map((key) => getEnumValue(enumItems, key))
      .filter((value) => value !== undefined) as string[];
    if (enumValues.length === 1) {
      return `When '${paramName}' is '${enumValues[0]}'.`;
    } else if (enumValues.length > 1) {
      return `When '${paramName}' is one of: ${enumValues.join(', ')}.`;
    } else {
      return undefined; // No matching enum values found
    }
  } else if (string != null) {
    if (string === '') {
      return `When '${paramName}' is empty.`;
    } else {
      return `When '${paramName}' is '${string}'.`;
    }
  } else {
    // Return undefined if no condition is defined
    return undefined;
  }
}

function getEnumValue(enumItems: Maybe<ParameterEnum[]> | undefined, key: Maybe<string>): string | undefined {
  const item = enumItems?.find((item) => item.key === key);
  return item ? item.value : undefined;
}

export default DetailsTab;
