import { useMsal } from '@azure/msal-react';
import useAppNavigate from './useAppNavigate';
import { useRoles } from './useRoles';
import { useTokenContext } from '../providers/TokenProvider';
import { useAuth } from '../providers/AuthProvider';
import React, { useCallback } from 'react';
import { loginRequest } from '../auth/authConfig';
import { AppRoute } from '../route/AppRoute';
import { ApiRole } from '../api/ApiRole.types';

export default function useLogin() {
  const { instance } = useMsal();
  const navigate = useAppNavigate();

  const { lastAttemptedRoute } = useAuth();
  const { setRoles } = useRoles();
  const { saveToken } = useTokenContext();

  const [loginInProgress, setLoginInProgress] = React.useState<boolean>(false);

  const login = useCallback(async () => {
    setLoginInProgress(true);
    try {
      const result = await instance.loginPopup(loginRequest);
      saveToken(result.idToken);
      setRoles(result.account.idTokenClaims?.roles as ApiRole[]);

      navigate(lastAttemptedRoute || AppRoute.Home);
    } catch (error) {
      console.error(error);
    } finally {
      setLoginInProgress(false);
    }
  }, [instance, navigate, lastAttemptedRoute, setRoles, saveToken]);

  return { login, loginInProgress };
}
