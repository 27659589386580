import { useState } from 'react';
import JoditEditor from '../../../components/Editor/components/jodit-editor/JoditEditor';
import { JoditEditorConnector } from '../../../components/Editor/components/jodit-editor/JoditEditorConnector';
import { ToggleButton } from 'primereact/togglebutton';
import MonacoEditor from '../../../components/MonacoEditor';

type Props = {
  referenceEditorConnector: JoditEditorConnector;
  contentEditorConnector: JoditEditorConnector;
  onEditorFocus: (connector: JoditEditorConnector) => void;
};

export default function LinkEditor(props: Props) {
  const [isReferenceSourceView, setIsReferenceSourceView] = useState(false);
  const [isContentSourceView, setIsContentSourceView] = useState(false);

  const toggleReferenceSourceView = () => {
    setIsReferenceSourceView(!isReferenceSourceView);
  };

  const toggleContentSourceView = () => {
    setIsContentSourceView(!isContentSourceView);
  };

  return (
    <>
      <div className="mb-3">
        <div className="field">
          <div className="d-flex justify-content-between align-items-center">
            <div className="title">Reference</div>
            <ToggleButton
              className="p-button-mini"
              onLabel="Source View"
              offLabel="Editor View"
              onIcon="pi pi-code"
              offIcon="pi pi-pencil"
              checked={isReferenceSourceView}
              onChange={toggleReferenceSourceView}
              style={{ marginLeft: '10px' }}
            />
          </div>
          <div className="mt-2"></div>
          {isReferenceSourceView ? (
            <MonacoEditor
              language="html"
              readOnly
              customHeight="40px"
              value={props.referenceEditorConnector.getValue()}
            />
          ) : (
            <JoditEditor
              isOneLine
              isWithoutStyles
              withoutToolbar
              placeholder="This link reference is empty and has no value."
              editorConnector={props.referenceEditorConnector}
              onFocus={() => props.onEditorFocus(props.referenceEditorConnector)}
            />
          )}
        </div>
      </div>
      <div className="field">
        <div className="d-flex justify-content-between align-items-center">
          <div className="title">Content</div>
          <ToggleButton
            className="p-button-mini"
            onLabel="Source View"
            offLabel="Editor View"
            onIcon="pi pi-code"
            offIcon="pi pi-pencil"
            checked={isContentSourceView}
            onChange={toggleContentSourceView}
            style={{ marginLeft: '10px' }}
          />
        </div>
        <div className="mt-2"></div>
        {isContentSourceView ? (
          <MonacoEditor language="html" readOnly customHeight="500px" value={props.contentEditorConnector.getValue()} />
        ) : (
          <JoditEditor
            placeholder="This link content is empty and has no value."
            editorConnector={props.contentEditorConnector}
            onFocus={() => props.onEditorFocus(props.contentEditorConnector)}
            isWithoutStyles
            customActions={[]}
          />
        )}
      </div>
    </>
  );
}
