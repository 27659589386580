import { api } from './graphApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: any; output: any };
  /** The NonEmptyString scalar type represents non empty textual data, represented as UTF‐8 character sequences with at least one character */
  NonEmptyString: { input: any; output: any };
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export type CodeBlock = {
  __typename?: 'CodeBlock';
  codeBlockId: Scalars['Int']['output'];
  codeBlockParameters: Array<Maybe<CodeBlockParameter>>;
  description?: Maybe<Scalars['String']['output']>;
  jsBody?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  systemName: Scalars['String']['output'];
};

export type CodeBlockAssignParametersInput = {
  parameters: Array<InputMaybe<CodeBlockParameterInput>>;
};

export type CodeBlockCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  jsBody?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['NonEmptyString']['input'];
};

export type CodeBlockParameter = {
  __typename?: 'CodeBlockParameter';
  codeBlock: CodeBlock;
  codeBlockId: Scalars['Int']['output'];
  codeBlockParameterId: Scalars['Int']['output'];
  parameter: Parameter;
  parameterId: Scalars['Int']['output'];
};

export type CodeBlockParameterInput = {
  parameterId: Scalars['Int']['input'];
};

export type CodeBlockReferences = {
  __typename?: 'CodeBlockReferences';
  any: Scalars['Boolean']['output'];
  entryPoints?: Maybe<Array<Maybe<EntryPoint>>>;
  subTemplates?: Maybe<Array<Maybe<SubTemplate>>>;
};

export type CodeBlockUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  jsBody?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Content = {
  __typename?: 'Content';
  body?: Maybe<Scalars['String']['output']>;
  contentId: Scalars['Int']['output'];
  extData?: Maybe<Scalars['String']['output']>;
  isEmpty: Scalars['Boolean']['output'];
  subject?: Maybe<Scalars['String']['output']>;
};

export type ContentUpdateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type EntryPoint = {
  __typename?: 'EntryPoint';
  description?: Maybe<Scalars['String']['output']>;
  entryPointId: Scalars['Int']['output'];
  entryPointParameters: Array<Maybe<EntryPointParameter>>;
  masterTemplate: MasterTemplate;
  masterTemplateId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  queryStringName: Scalars['String']['output'];
  templates: Array<Maybe<Template>>;
  workflow: Workflow;
  workflowId: Scalars['Int']['output'];
};

export type EntryPointAssignParametersInput = {
  parameters: Array<InputMaybe<EntryPointParameterInput>>;
};

export type EntryPointContentMatrixRow = {
  __typename?: 'EntryPointContentMatrixRow';
  body?: Maybe<Scalars['String']['output']>;
  contentId?: Maybe<Scalars['Int']['output']>;
  parameterValues: Array<KeyValuePairOfInt32AndString>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EntryPointCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  masterTemplateId: Scalars['Int']['input'];
  name: Scalars['NonEmptyString']['input'];
  queryStringName?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowId: Scalars['Int']['input'];
};

export type EntryPointMatrixInput = {
  /** Parameter values for rendering. */
  parameterValues?: InputMaybe<Array<InputMaybe<ParameterInput>>>;
  /** Decision parameters for content selection. */
  parameters: Array<InputMaybe<ParameterInput>>;
  render: Scalars['Boolean']['input'];
};

export type EntryPointParameter = {
  __typename?: 'EntryPointParameter';
  entryPoint: EntryPoint;
  entryPointId: Scalars['Int']['output'];
  entryPointParameterId: Scalars['Int']['output'];
  isDecision: Scalars['Boolean']['output'];
  parameter: Parameter;
  parameterId: Scalars['Int']['output'];
};

export type EntryPointParameterInput = {
  isDecision: Scalars['Boolean']['input'];
  parameterId: Scalars['Int']['input'];
};

export type EntryPointStatisticsBasic = {
  __typename?: 'EntryPointStatisticsBasic';
  description?: Maybe<Scalars['String']['output']>;
  entryPointId: Scalars['Int']['output'];
  maxContentCount: Scalars['Int']['output'];
  missingContentCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type EntryPointStatisticsDetail = {
  __typename?: 'EntryPointStatisticsDetail';
  name?: Maybe<Scalars['String']['output']>;
  parameterId: Scalars['Int']['output'];
  values: Array<EntryPointStatisticsParameterDetail>;
};

export type EntryPointStatisticsDetailInput = {
  parameters: Array<InputMaybe<ParameterInput>>;
};

export type EntryPointStatisticsParameterDetail = {
  __typename?: 'EntryPointStatisticsParameterDetail';
  maxContentCount: Scalars['Int']['output'];
  missingContentCount: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type EntryPointUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  masterTemplateId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  queryStringName?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['Int']['input']>;
};

export type Image = {
  __typename?: 'Image';
  alternateText: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  height: Scalars['Int']['output'];
  imageId: Scalars['Int']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  originalFileName?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  systemName: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type ImageCreateInput = {
  alternateText: Scalars['NonEmptyString']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Int']['input'];
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['NonEmptyString']['input'];
  originalFileName?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  width: Scalars['Int']['input'];
};

export type ImageReferences = {
  __typename?: 'ImageReferences';
  any: Scalars['Boolean']['output'];
  entryPoints?: Maybe<Array<Maybe<EntryPoint>>>;
  links?: Maybe<Array<Maybe<Link>>>;
  subTemplates?: Maybe<Array<Maybe<SubTemplate>>>;
};

export type ImageUpdateInput = {
  alternateText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  originalFileName?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type InvalidObject = {
  __typename?: 'InvalidObject';
  id: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type KeyValuePairOfInt32AndString = {
  __typename?: 'KeyValuePairOfInt32AndString';
  key: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Link = {
  __typename?: 'Link';
  content?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extData?: Maybe<Scalars['String']['output']>;
  linkId: Scalars['Int']['output'];
  linkParameters: Array<Maybe<LinkParameter>>;
  name: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  systemName: Scalars['String']['output'];
};

export type LinkAssignParametersInput = {
  parameters: Array<InputMaybe<LinkParameterInput>>;
};

export type LinkCreateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['NonEmptyString']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type LinkParameter = {
  __typename?: 'LinkParameter';
  link: Link;
  linkId: Scalars['Int']['output'];
  linkParameterId: Scalars['Int']['output'];
  parameter: Parameter;
  parameterId: Scalars['Int']['output'];
};

export type LinkParameterInput = {
  parameterId: Scalars['Int']['input'];
};

export type LinkPreviewByContentParametersInput = {
  content?: InputMaybe<RenderedLinkInput>;
  linkId: Scalars['Int']['input'];
  parameters?: InputMaybe<Array<InputMaybe<ParameterInput>>>;
};

export type LinkReferences = {
  __typename?: 'LinkReferences';
  any: Scalars['Boolean']['output'];
  entryPoints?: Maybe<Array<Maybe<EntryPoint>>>;
  subTemplates?: Maybe<Array<Maybe<SubTemplate>>>;
};

export type LinkUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type MasterTemplate = {
  __typename?: 'MasterTemplate';
  description?: Maybe<Scalars['String']['output']>;
  htmlBody?: Maybe<Scalars['String']['output']>;
  masterTemplateId: Scalars['Int']['output'];
  mjmlBody?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type MasterTemplateCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  htmlBody?: InputMaybe<Scalars['String']['input']>;
  mjmlBody?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['NonEmptyString']['input'];
};

export type MasterTemplateReferences = {
  __typename?: 'MasterTemplateReferences';
  any: Scalars['Boolean']['output'];
  entryPoints?: Maybe<Array<Maybe<EntryPoint>>>;
};

export type MasterTemplateUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  htmlBody?: InputMaybe<Scalars['String']['input']>;
  mjmlBody?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignCodeBlockParameters?: Maybe<CodeBlock>;
  assignEntryPointParameters?: Maybe<EntryPoint>;
  assignLinkParameters?: Maybe<Link>;
  assignSubTemplateParameters?: Maybe<SubTemplate>;
  clearContent?: Maybe<Content>;
  createCodeBlock?: Maybe<CodeBlock>;
  createEntryPoint?: Maybe<EntryPoint>;
  createImage?: Maybe<Image>;
  createLink?: Maybe<Link>;
  createMasterTemplate?: Maybe<MasterTemplate>;
  createParameter?: Maybe<Parameter>;
  createSampleData: Scalars['Boolean']['output'];
  createSubTemplate?: Maybe<SubTemplate>;
  createWorkflow?: Maybe<Workflow>;
  deleteCodeBlock: Scalars['Boolean']['output'];
  deleteEntryPoint: Scalars['Boolean']['output'];
  deleteImage: Scalars['Boolean']['output'];
  deleteLink: Scalars['Boolean']['output'];
  deleteMasterTemplate: Scalars['Boolean']['output'];
  deleteParameter: Scalars['Boolean']['output'];
  deleteSubTemplate: Scalars['Boolean']['output'];
  deleteTemplate: Scalars['Boolean']['output'];
  deleteWorkflow: Scalars['Boolean']['output'];
  publishEntryPoint: Scalars['Boolean']['output'];
  publishSubTemplate: Scalars['Boolean']['output'];
  publishTemplate: Scalars['Boolean']['output'];
  sendTemplatePreviewByContentIdParameters: Scalars['Boolean']['output'];
  updateCodeBlock?: Maybe<CodeBlock>;
  updateContent?: Maybe<Content>;
  updateEntryPoint?: Maybe<EntryPoint>;
  updateImage?: Maybe<Image>;
  updateLink?: Maybe<Link>;
  updateMasterTemplate?: Maybe<MasterTemplate>;
  updateParameter?: Maybe<Parameter>;
  updateSubTemplate?: Maybe<SubTemplate>;
  updateSubTemplateConditionalUse?: Maybe<SubTemplate>;
  updateTemplate?: Maybe<Template>;
  updateWorkflow?: Maybe<Workflow>;
};

export type MutationAssignCodeBlockParametersArgs = {
  id: Scalars['ID']['input'];
  input: CodeBlockAssignParametersInput;
};

export type MutationAssignEntryPointParametersArgs = {
  id: Scalars['ID']['input'];
  input: EntryPointAssignParametersInput;
};

export type MutationAssignLinkParametersArgs = {
  id: Scalars['ID']['input'];
  input: LinkAssignParametersInput;
};

export type MutationAssignSubTemplateParametersArgs = {
  id: Scalars['ID']['input'];
  input: SubTemplateAssignParametersInput;
};

export type MutationClearContentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCreateCodeBlockArgs = {
  input: CodeBlockCreateInput;
};

export type MutationCreateEntryPointArgs = {
  input: EntryPointCreateInput;
};

export type MutationCreateImageArgs = {
  input: ImageCreateInput;
};

export type MutationCreateLinkArgs = {
  input: LinkCreateInput;
};

export type MutationCreateMasterTemplateArgs = {
  input: MasterTemplateCreateInput;
};

export type MutationCreateParameterArgs = {
  input: ParameterCreateInput;
};

export type MutationCreateSubTemplateArgs = {
  input: SubTemplateCreateInput;
};

export type MutationCreateWorkflowArgs = {
  input: WorkflowCreateInput;
};

export type MutationDeleteCodeBlockArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteEntryPointArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteImageArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteLinkArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteMasterTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteParameterArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSubTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteWorkflowArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPublishEntryPointArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPublishSubTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPublishTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSendTemplatePreviewByContentIdParametersArgs = {
  input?: InputMaybe<SendTemplatePreviewByContentIdParametersInput>;
};

export type MutationUpdateCodeBlockArgs = {
  id: Scalars['ID']['input'];
  input: CodeBlockUpdateInput;
};

export type MutationUpdateContentArgs = {
  id: Scalars['ID']['input'];
  input: ContentUpdateInput;
};

export type MutationUpdateEntryPointArgs = {
  id: Scalars['ID']['input'];
  input: EntryPointUpdateInput;
};

export type MutationUpdateImageArgs = {
  id: Scalars['Int']['input'];
  input: ImageUpdateInput;
};

export type MutationUpdateLinkArgs = {
  id: Scalars['Int']['input'];
  input: LinkUpdateInput;
};

export type MutationUpdateMasterTemplateArgs = {
  id: Scalars['ID']['input'];
  input: MasterTemplateUpdateInput;
};

export type MutationUpdateParameterArgs = {
  id: Scalars['ID']['input'];
  input: ParameterUpdateInput;
};

export type MutationUpdateSubTemplateArgs = {
  id: Scalars['ID']['input'];
  input: SubTemplateUpdateInput;
};

export type MutationUpdateSubTemplateConditionalUseArgs = {
  id: Scalars['ID']['input'];
  input: SubTemplateConditionalUseInput;
};

export type MutationUpdateTemplateArgs = {
  id: Scalars['ID']['input'];
  input: TemplateUpdateInput;
};

export type MutationUpdateWorkflowArgs = {
  id: Scalars['ID']['input'];
  input: WorkflowUpdateInput;
};

export type Parameter = {
  __typename?: 'Parameter';
  anonymize: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  entryPointParameters: Array<Maybe<EntryPointParameter>>;
  enumItems?: Maybe<Array<ParameterEnum>>;
  extData?: Maybe<Scalars['String']['output']>;
  mandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parameterId: Scalars['Int']['output'];
  queryStringDataType?: Maybe<SysQueryStringDataType>;
  queryStringDataTypeId: SysQueryStringDataTypeId;
  queryStringName: Scalars['String']['output'];
  sampleValue?: Maybe<Scalars['String']['output']>;
  sysCategory?: Maybe<Scalars['String']['output']>;
  sysDataType?: Maybe<SysDataType>;
  sysDataTypeId: SysDataTypeId;
  sysParameterType?: Maybe<SysParameterType>;
  sysParameterTypeId: SysParameterTypeId;
  sysValue?: Maybe<Scalars['String']['output']>;
  systemName: Scalars['String']['output'];
};

export type ParameterCreateInput = {
  anonymize: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  enumItems?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  mandatory: Scalars['Boolean']['input'];
  name: Scalars['NonEmptyString']['input'];
  queryStringDataTypeId: SysQueryStringDataTypeId;
  queryStringName: Scalars['NonEmptyString']['input'];
  sampleValue?: InputMaybe<Scalars['String']['input']>;
  sysCategory: Scalars['NonEmptyString']['input'];
  sysDataTypeId: SysDataTypeId;
  sysParameterTypeId: SysParameterTypeId;
};

export type ParameterEnum = {
  __typename?: 'ParameterEnum';
  enabled: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ParameterInput = {
  parameterId: Scalars['Int']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ParameterReferences = {
  __typename?: 'ParameterReferences';
  any: Scalars['Boolean']['output'];
  codeBlocks?: Maybe<Array<Maybe<CodeBlock>>>;
  entryPoints?: Maybe<Array<Maybe<EntryPoint>>>;
  subTemplates?: Maybe<Array<Maybe<SubTemplate>>>;
};

export type ParameterUpdateInput = {
  anonymize?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enumItems?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  mandatory?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  queryStringDataTypeId?: InputMaybe<SysQueryStringDataTypeId>;
  queryStringName?: InputMaybe<Scalars['String']['input']>;
  sampleValue?: InputMaybe<Scalars['String']['input']>;
  sysCategory?: InputMaybe<Scalars['String']['input']>;
  sysDataTypeId?: InputMaybe<SysDataTypeId>;
  sysParameterTypeId?: InputMaybe<SysParameterTypeId>;
};

export type Query = {
  __typename?: 'Query';
  allCodeBlocks?: Maybe<Array<Maybe<CodeBlock>>>;
  allEntryPointStatisticsBasic?: Maybe<Array<Maybe<EntryPointStatisticsBasic>>>;
  allEntryPoints?: Maybe<Array<Maybe<EntryPoint>>>;
  allImages?: Maybe<Array<Maybe<Image>>>;
  allLinks?: Maybe<Array<Maybe<Link>>>;
  allMasterTemplates?: Maybe<Array<Maybe<MasterTemplate>>>;
  allParameters?: Maybe<Array<Maybe<Parameter>>>;
  allSubTemplates?: Maybe<Array<Maybe<SubTemplate>>>;
  allSysDataTypes?: Maybe<Array<Maybe<SysDataType>>>;
  allSysParameterTypes?: Maybe<Array<Maybe<SysParameterType>>>;
  allSysQueryStringDataType?: Maybe<Array<Maybe<SysQueryStringDataType>>>;
  allTemplates?: Maybe<Array<Maybe<Template>>>;
  allWorkflows?: Maybe<Array<Maybe<Workflow>>>;
  codeBlockById?: Maybe<CodeBlock>;
  codeBlockPreviewById?: Maybe<Scalars['String']['output']>;
  codeBlockPreviewByIdWithBodyParameterValues?: Maybe<Scalars['String']['output']>;
  codeBlockPreviewByJsBody?: Maybe<Scalars['String']['output']>;
  codeBlockReferences?: Maybe<CodeBlockReferences>;
  entryPointById?: Maybe<EntryPoint>;
  entryPointContentMatrix?: Maybe<Array<Maybe<EntryPointContentMatrixRow>>>;
  entryPointCurrentTemplateId: Scalars['Int']['output'];
  entryPointStatisticsDetail?: Maybe<Array<Maybe<EntryPointStatisticsDetail>>>;
  imageById?: Maybe<Image>;
  imageReferences?: Maybe<ImageReferences>;
  invalidObjectsForContentId?: Maybe<Array<Maybe<InvalidObject>>>;
  linkById?: Maybe<Link>;
  linkPreviewByContentParameters?: Maybe<RenderedLink>;
  linkPreviewById?: Maybe<RenderedLink>;
  linkReferences?: Maybe<LinkReferences>;
  masterTemplateById?: Maybe<MasterTemplate>;
  masterTemplateReferences?: Maybe<MasterTemplateReferences>;
  parameterById?: Maybe<Parameter>;
  parameterReferences?: Maybe<ParameterReferences>;
  subTemplateById?: Maybe<SubTemplate>;
  subTemplateContentByParameters?: Maybe<Content>;
  /** @deprecated Use subTemplatePreviewByContentIdParameters instead */
  subTemplatePreviewByContentId?: Maybe<Scalars['String']['output']>;
  subTemplatePreviewByContentIdParameters?: Maybe<Scalars['String']['output']>;
  subTemplatePreviewByParameters?: Maybe<Scalars['String']['output']>;
  subTemplateReferences?: Maybe<SubTemplateReferences>;
  sysDataTypeById?: Maybe<SysDataType>;
  sysParameterTypeById?: Maybe<SysParameterType>;
  sysQueryStringDataTypeById?: Maybe<SysQueryStringDataType>;
  /**
   * Get all decision parameters of all SubTemplates of the Template that are not EntryPoint decision parameters.
   *
   *
   * **Returns:**
   * List of parameters.
   */
  templateAllSubTemplateDecisionParameters?: Maybe<Array<Maybe<Parameter>>>;
  templateById?: Maybe<Template>;
  templateContentByParameters?: Maybe<Content>;
  /** @deprecated Use templatePreviewByContentParameters instead */
  templatePreviewByContentBody?: Maybe<Scalars['String']['output']>;
  templatePreviewByContentId?: Maybe<RenderedContent>;
  templatePreviewByContentIdParameters?: Maybe<RenderedContent>;
  templatePreviewByContentParameters?: Maybe<RenderedContent>;
  templatePreviewByParameters?: Maybe<RenderedContent>;
  validateCodeBlockInsertToContent: Scalars['Boolean']['output'];
  validateEntryPoint: Scalars['Boolean']['output'];
  validateLinkInsertToContent?: Maybe<Scalars['String']['output']>;
  validateSubTemplateInsertToContent?: Maybe<Scalars['String']['output']>;
  workflowById?: Maybe<Workflow>;
  workflowReferences?: Maybe<WorkflowReferences>;
};

export type QueryCodeBlockByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCodeBlockPreviewByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCodeBlockPreviewByIdWithBodyParameterValuesArgs = {
  id: Scalars['ID']['input'];
  jsBody?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCodeBlockPreviewByJsBodyArgs = {
  jsBody?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCodeBlockReferencesArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEntryPointByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEntryPointContentMatrixArgs = {
  id: Scalars['Int']['input'];
  input: EntryPointMatrixInput;
};

export type QueryEntryPointCurrentTemplateIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryEntryPointStatisticsDetailArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<EntryPointStatisticsDetailInput>;
};

export type QueryImageByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryImageReferencesArgs = {
  id: Scalars['Int']['input'];
};

export type QueryInvalidObjectsForContentIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLinkByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryLinkPreviewByContentParametersArgs = {
  input?: InputMaybe<LinkPreviewByContentParametersInput>;
};

export type QueryLinkPreviewByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryLinkReferencesArgs = {
  id: Scalars['Int']['input'];
};

export type QueryMasterTemplateByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMasterTemplateReferencesArgs = {
  id: Scalars['Int']['input'];
};

export type QueryParameterByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryParameterReferencesArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySubTemplateByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySubTemplateContentByParametersArgs = {
  input: SubTemplateContentByParametersInput;
};

export type QuerySubTemplatePreviewByContentIdArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySubTemplatePreviewByContentIdParametersArgs = {
  input?: InputMaybe<SubTemplatePreviewByContentIdParametersInput>;
};

export type QuerySubTemplatePreviewByParametersArgs = {
  input: SubTemplateContentByParametersInput;
};

export type QuerySubTemplateReferencesArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySysDataTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySysParameterTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySysQueryStringDataTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTemplateAllSubTemplateDecisionParametersArgs = {
  id: Scalars['Int']['input'];
};

export type QueryTemplateByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTemplateContentByParametersArgs = {
  input: TemplateContentByParametersInput;
};

export type QueryTemplatePreviewByContentBodyArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTemplatePreviewByContentIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTemplatePreviewByContentIdParametersArgs = {
  input?: InputMaybe<TemplatePreviewByContentIdParametersInput>;
};

export type QueryTemplatePreviewByContentParametersArgs = {
  input?: InputMaybe<TemplatePreviewByContentParametersInput>;
};

export type QueryTemplatePreviewByParametersArgs = {
  input: TemplatePreviewByParametersInput;
};

export type QueryValidateCodeBlockInsertToContentArgs = {
  contentId: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};

export type QueryValidateEntryPointArgs = {
  id: Scalars['ID']['input'];
};

export type QueryValidateLinkInsertToContentArgs = {
  contentId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type QueryValidateSubTemplateInsertToContentArgs = {
  contentId: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};

export type QueryWorkflowByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWorkflowReferencesArgs = {
  id: Scalars['ID']['input'];
};

export type RecipientInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  notificationToken?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type RenderedContent = {
  __typename?: 'RenderedContent';
  body?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type RenderedLink = {
  __typename?: 'RenderedLink';
  content?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
};

export type RenderedLinkInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type SendTemplatePreviewByContentIdParametersInput = {
  contentId: Scalars['Int']['input'];
  parameters: Array<InputMaybe<ParameterInput>>;
  recipient: RecipientInput;
};

export type SubTemplate = {
  __typename?: 'SubTemplate';
  conditionalUse?: Maybe<SubTemplateConditionalUse>;
  defaultLanguage?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extData?: Maybe<Scalars['String']['output']>;
  languagesUsingDefaultLanguage?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  subTemplateId: Scalars['Int']['output'];
  subTemplateParameters: Array<Maybe<SubTemplateParameter>>;
  systemName: Scalars['String']['output'];
};

export type SubTemplateAssignParameterInput = {
  isDecision: Scalars['Boolean']['input'];
  parameterId: Scalars['Int']['input'];
};

export type SubTemplateAssignParametersInput = {
  parameters: Array<InputMaybe<SubTemplateAssignParameterInput>>;
};

export type SubTemplateConditionalUse = {
  __typename?: 'SubTemplateConditionalUse';
  conditions: Array<Maybe<SubTemplateConditionalUseCondition>>;
};

export type SubTemplateConditionalUseCondition = {
  __typename?: 'SubTemplateConditionalUseCondition';
  parameterId: Scalars['Int']['output'];
  value: SubTemplateConditionalUseConditionValue;
};

export type SubTemplateConditionalUseConditionInput = {
  parameterId: Scalars['Int']['input'];
  value: SubTemplateConditionalUseConditionValueInput;
};

export type SubTemplateConditionalUseConditionValue = {
  __typename?: 'SubTemplateConditionalUseConditionValue';
  bool?: Maybe<Scalars['Boolean']['output']>;
  dateFrom?: Maybe<Scalars['Date']['output']>;
  dateTo?: Maybe<Scalars['Date']['output']>;
  doubleFrom?: Maybe<Scalars['Float']['output']>;
  doubleTo?: Maybe<Scalars['Float']['output']>;
  enums?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  intFrom?: Maybe<Scalars['Int']['output']>;
  intTo?: Maybe<Scalars['Int']['output']>;
  string?: Maybe<Scalars['String']['output']>;
};

export type SubTemplateConditionalUseConditionValueInput = {
  bool?: InputMaybe<Scalars['Boolean']['input']>;
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  dateTo?: InputMaybe<Scalars['Date']['input']>;
  doubleFrom?: InputMaybe<Scalars['Float']['input']>;
  doubleTo?: InputMaybe<Scalars['Float']['input']>;
  enums?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  intFrom?: InputMaybe<Scalars['Int']['input']>;
  intTo?: InputMaybe<Scalars['Int']['input']>;
  string?: InputMaybe<Scalars['String']['input']>;
};

export type SubTemplateConditionalUseInput = {
  conditions: Array<InputMaybe<SubTemplateConditionalUseConditionInput>>;
};

export type SubTemplateContentByParametersInput = {
  parameters: Array<InputMaybe<ParameterInput>>;
  subTemplateId: Scalars['Int']['input'];
};

export type SubTemplateCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['NonEmptyString']['input'];
};

export type SubTemplateParameter = {
  __typename?: 'SubTemplateParameter';
  isDecision: Scalars['Boolean']['output'];
  parameter?: Maybe<Parameter>;
  parameterId: Scalars['Int']['output'];
};

export type SubTemplatePreviewByContentIdParametersInput = {
  contentId: Scalars['Int']['input'];
  parameters: Array<InputMaybe<ParameterInput>>;
};

export type SubTemplateReferences = {
  __typename?: 'SubTemplateReferences';
  any: Scalars['Boolean']['output'];
  entryPoints?: Maybe<Array<Maybe<EntryPoint>>>;
};

export type SubTemplateUpdateInput = {
  defaultLanguage?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  languagesUsingDefaultLanguage?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SysDataType = {
  __typename?: 'SysDataType';
  name: Scalars['String']['output'];
  sysDataTypeId: SysDataTypeId;
};

export enum SysDataTypeId {
  Bool = 'BOOL',
  DateTime = 'DATE_TIME',
  Double = 'DOUBLE',
  Enum = 'ENUM',
  Int = 'INT',
  String = 'STRING',
}

export type SysParameterType = {
  __typename?: 'SysParameterType';
  name: Scalars['String']['output'];
  parameters: Array<Maybe<Parameter>>;
  sysParameterTypeId: SysParameterTypeId;
};

export enum SysParameterTypeId {
  DataHolder = 'DATA_HOLDER',
  Decision = 'DECISION',
  System = 'SYSTEM',
}

export type SysQueryStringDataType = {
  __typename?: 'SysQueryStringDataType';
  name: Scalars['String']['output'];
  sysQueryStringDataTypeId: SysQueryStringDataTypeId;
};

export enum SysQueryStringDataTypeId {
  Bool = 'BOOL',
  DateTime = 'DATE_TIME',
  Double = 'DOUBLE',
  Enum = 'ENUM',
  Int = 'INT',
  String = 'STRING',
}

export type Template = {
  __typename?: 'Template';
  defaultLanguage?: Maybe<Scalars['String']['output']>;
  entryPoint: EntryPoint;
  entryPointId: Scalars['Int']['output'];
  extData?: Maybe<Scalars['String']['output']>;
  languagesUsingDefaultLanguage?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  templateId: Scalars['Int']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export type TemplateContentByParametersInput = {
  parameters: Array<InputMaybe<ParameterInput>>;
  templateId: Scalars['Int']['input'];
};

export type TemplatePreviewByContentIdParametersInput = {
  contentId: Scalars['Int']['input'];
  parameters: Array<InputMaybe<ParameterInput>>;
};

export type TemplatePreviewByContentParametersInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  parameters?: InputMaybe<Array<InputMaybe<ParameterInput>>>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type TemplatePreviewByParametersInput = {
  parameters: Array<InputMaybe<ParameterInput>>;
  templateId: Scalars['Int']['input'];
};

export type TemplateUpdateInput = {
  defaultLanguage?: InputMaybe<Scalars['String']['input']>;
  languagesUsingDefaultLanguage?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Workflow = {
  __typename?: 'Workflow';
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['Boolean']['output'];
  extData?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notification: Scalars['Boolean']['output'];
  sms: Scalars['Boolean']['output'];
  workflowId: Scalars['Int']['output'];
};

export type WorkflowCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Boolean']['input'];
  name: Scalars['NonEmptyString']['input'];
  notification: Scalars['Boolean']['input'];
  sms: Scalars['Boolean']['input'];
};

export type WorkflowReferences = {
  __typename?: 'WorkflowReferences';
  any: Scalars['Boolean']['output'];
  entryPoints?: Maybe<Array<Maybe<EntryPoint>>>;
};

export type WorkflowUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EntryPointsQueryVariables = Exact<{ [key: string]: never }>;

export type EntryPointsQuery = {
  __typename?: 'Query';
  allEntryPoints?: Array<{
    __typename?: 'EntryPoint';
    entryPointId: number;
    name: string;
    description?: string | null;
    masterTemplate: { __typename?: 'MasterTemplate'; name: string };
    workflow: { __typename?: 'Workflow'; name: string };
  } | null> | null;
};

export type EntryPointStatisticsBasicQueryVariables = Exact<{ [key: string]: never }>;

export type EntryPointStatisticsBasicQuery = {
  __typename?: 'Query';
  allEntryPointStatisticsBasic?: Array<{
    __typename?: 'EntryPointStatisticsBasic';
    entryPointId: number;
    name: string;
    description?: string | null;
    maxContentCount: number;
    missingContentCount: number;
  } | null> | null;
};

export type EntryPointStatisticsBasic2QueryVariables = Exact<{ [key: string]: never }>;

export type EntryPointStatisticsBasic2Query = {
  __typename?: 'Query';
  allEntryPointStatisticsBasic?: Array<{
    __typename?: 'EntryPointStatisticsBasic';
    entryPointId: number;
    maxContentCount: number;
    missingContentCount: number;
  } | null> | null;
};

export type AssignEntryPointParametersMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: EntryPointAssignParametersInput;
}>;

export type AssignEntryPointParametersMutation = {
  __typename?: 'Mutation';
  assignEntryPointParameters?: { __typename?: 'EntryPoint'; entryPointId: number } | null;
};

export type ClearContentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ClearContentMutation = {
  __typename?: 'Mutation';
  clearContent?: { __typename?: 'Content'; isEmpty: boolean; body?: string | null } | null;
};

export type CreateEntryPointMutationVariables = Exact<{
  input: EntryPointCreateInput;
}>;

export type CreateEntryPointMutation = {
  __typename?: 'Mutation';
  createEntryPoint?: { __typename?: 'EntryPoint'; entryPointId: number } | null;
};

export type DeleteEntryPointMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteEntryPointMutation = { __typename?: 'Mutation'; deleteEntryPoint: boolean };

export type EntryPointQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type EntryPointQuery = {
  __typename?: 'Query';
  entryPointById?: {
    __typename?: 'EntryPoint';
    entryPointId: number;
    name: string;
    description?: string | null;
    queryStringName: string;
    workflowId: number;
    masterTemplateId: number;
    entryPointParameters: Array<{
      __typename?: 'EntryPointParameter';
      parameterId: number;
      isDecision: boolean;
      parameter: {
        __typename?: 'Parameter';
        name: string;
        systemName: string;
        sampleValue?: string | null;
        description?: string | null;
        sysDataTypeId: SysDataTypeId;
        sysParameterTypeId: SysParameterTypeId;
      };
    } | null>;
  } | null;
};

export type EntryPointDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type EntryPointDetailQuery = {
  __typename?: 'Query';
  entryPointById?: {
    __typename?: 'EntryPoint';
    entryPointId: number;
    name: string;
    description?: string | null;
    queryStringName: string;
    entryPointParameters: Array<{
      __typename?: 'EntryPointParameter';
      entryPointParameterId: number;
      entryPointId: number;
      parameterId: number;
      isDecision: boolean;
      parameter: {
        __typename?: 'Parameter';
        parameterId: number;
        sysDataTypeId: SysDataTypeId;
        sysParameterTypeId: SysParameterTypeId;
        name: string;
        systemName: string;
        sampleValue?: string | null;
        description?: string | null;
        sysCategory?: string | null;
        sysParameterType?: {
          __typename?: 'SysParameterType';
          sysParameterTypeId: SysParameterTypeId;
          name: string;
        } | null;
        enumItems?: Array<{ __typename?: 'ParameterEnum'; enabled: boolean; key: string; value: string }> | null;
      };
    } | null>;
  } | null;
};

export type EntryPointCurrentTemplateIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type EntryPointCurrentTemplateIdQuery = { __typename?: 'Query'; entryPointCurrentTemplateId: number };

export type EntryPointContentMatrixQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  input: EntryPointMatrixInput;
}>;

export type EntryPointContentMatrixQuery = {
  __typename?: 'Query';
  entryPointContentMatrix?: Array<{
    __typename?: 'EntryPointContentMatrixRow';
    contentId?: number | null;
    subject?: string | null;
    body?: string | null;
    parameterValues: Array<{ __typename?: 'KeyValuePairOfInt32AndString'; key: number; value: string }>;
  } | null> | null;
};

export type TemplatePreviewByContentIdParametersQueryVariables = Exact<{
  input?: InputMaybe<TemplatePreviewByContentIdParametersInput>;
}>;

export type TemplatePreviewByContentIdParametersQuery = {
  __typename?: 'Query';
  templatePreviewByContentIdParameters?: {
    __typename?: 'RenderedContent';
    subject?: string | null;
    body?: string | null;
  } | null;
};

export type TemplatePreviewByContentParametersQueryVariables = Exact<{
  input?: InputMaybe<TemplatePreviewByContentParametersInput>;
}>;

export type TemplatePreviewByContentParametersQuery = {
  __typename?: 'Query';
  templatePreviewByContentParameters?: {
    __typename?: 'RenderedContent';
    subject?: string | null;
    body?: string | null;
  } | null;
};

export type SendTemplatePreviewByContentIdParametersMutationVariables = Exact<{
  input: SendTemplatePreviewByContentIdParametersInput;
}>;

export type SendTemplatePreviewByContentIdParametersMutation = {
  __typename?: 'Mutation';
  sendTemplatePreviewByContentIdParameters: boolean;
};

export type PublishEntryPointMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublishEntryPointMutation = { __typename?: 'Mutation'; publishEntryPoint: boolean };

export type EntryPointStatisticsDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<EntryPointStatisticsDetailInput>;
}>;

export type EntryPointStatisticsDetailQuery = {
  __typename?: 'Query';
  entryPointStatisticsDetail?: Array<{
    __typename?: 'EntryPointStatisticsDetail';
    name?: string | null;
    parameterId: number;
    values: Array<{
      __typename?: 'EntryPointStatisticsParameterDetail';
      maxContentCount: number;
      missingContentCount: number;
      name?: string | null;
      value: string;
    }>;
  } | null> | null;
};

export type TemplateContentByParametersQueryVariables = Exact<{
  input: TemplateContentByParametersInput;
}>;

export type TemplateContentByParametersQuery = {
  __typename?: 'Query';
  templateContentByParameters?: {
    __typename?: 'Content';
    contentId: number;
    subject?: string | null;
    body?: string | null;
    isEmpty: boolean;
  } | null;
};

export type TemplateByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type TemplateByIdQuery = {
  __typename?: 'Query';
  templateById?: {
    __typename?: 'Template';
    templateId: number;
    entryPointId: number;
    version?: number | null;
    extData?: string | null;
    defaultLanguage?: string | null;
    languagesUsingDefaultLanguage?: Array<string | null> | null;
    entryPoint: {
      __typename?: 'EntryPoint';
      entryPointId: number;
      masterTemplateId: number;
      workflowId: number;
      name: string;
    };
  } | null;
};

export type UpdateTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: TemplateUpdateInput;
}>;

export type UpdateTemplateMutation = {
  __typename?: 'Mutation';
  updateTemplate?: {
    __typename?: 'Template';
    defaultLanguage?: string | null;
    languagesUsingDefaultLanguage?: Array<string | null> | null;
  } | null;
};

export type UpdateEntryPointMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: EntryPointUpdateInput;
}>;

export type UpdateEntryPointMutation = {
  __typename?: 'Mutation';
  updateEntryPoint?: { __typename?: 'EntryPoint'; entryPointId: number } | null;
};

export type UpdateEntryPointContentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ContentUpdateInput;
}>;

export type UpdateEntryPointContentMutation = {
  __typename?: 'Mutation';
  updateContent?: { __typename?: 'Content'; contentId: number } | null;
};

export type ValidateEntryPointQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ValidateEntryPointQuery = { __typename?: 'Query'; validateEntryPoint: boolean };

export type ImagesQueryVariables = Exact<{ [key: string]: never }>;

export type ImagesQuery = {
  __typename?: 'Query';
  allImages?: Array<{
    __typename?: 'Image';
    imageId: number;
    name: string;
    description?: string | null;
    systemName: string;
  } | null> | null;
};

export type CreateImageMutationVariables = Exact<{
  input: ImageCreateInput;
}>;

export type CreateImageMutation = {
  __typename?: 'Mutation';
  createImage?: { __typename?: 'Image'; imageId: number } | null;
};

export type DeleteImageMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteImageMutation = { __typename?: 'Mutation'; deleteImage: boolean };

export type ImageReferencesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ImageReferencesQuery = {
  __typename?: 'Query';
  imageReferences?: {
    __typename?: 'ImageReferences';
    any: boolean;
    entryPoints?: Array<{ __typename?: 'EntryPoint'; name: string } | null> | null;
    subTemplates?: Array<{ __typename?: 'SubTemplate'; name: string } | null> | null;
    links?: Array<{ __typename?: 'Link'; name: string } | null> | null;
  } | null;
};

export type ImageQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ImageQuery = {
  __typename?: 'Query';
  imageById?: {
    __typename?: 'Image';
    imageId: number;
    name: string;
    description?: string | null;
    alternateText: string;
    width: number;
    height: number;
    source?: string | null;
  } | null;
};

export type UpdateImageMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: ImageUpdateInput;
}>;

export type UpdateImageMutation = {
  __typename?: 'Mutation';
  updateImage?: { __typename?: 'Image'; imageId: number } | null;
};

export type LinksQueryVariables = Exact<{ [key: string]: never }>;

export type LinksQuery = {
  __typename?: 'Query';
  allLinks?: Array<{
    __typename?: 'Link';
    linkId: number;
    name: string;
    description?: string | null;
    systemName: string;
  } | null> | null;
};

export type AssignLinkParametersMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: LinkAssignParametersInput;
}>;

export type AssignLinkParametersMutation = {
  __typename?: 'Mutation';
  assignLinkParameters?: { __typename?: 'Link'; linkId: number } | null;
};

export type CreateLinkMutationVariables = Exact<{
  input: LinkCreateInput;
}>;

export type CreateLinkMutation = {
  __typename?: 'Mutation';
  createLink?: { __typename?: 'Link'; linkId: number } | null;
};

export type DeleteLinkMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteLinkMutation = { __typename?: 'Mutation'; deleteLink: boolean };

export type LinkReferencesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type LinkReferencesQuery = {
  __typename?: 'Query';
  linkReferences?: {
    __typename?: 'LinkReferences';
    any: boolean;
    entryPoints?: Array<{ __typename?: 'EntryPoint'; name: string } | null> | null;
    subTemplates?: Array<{ __typename?: 'SubTemplate'; name: string } | null> | null;
  } | null;
};

export type LinkQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type LinkQuery = {
  __typename?: 'Query';
  linkById?: {
    __typename?: 'Link';
    linkId: number;
    name: string;
    description?: string | null;
    reference?: string | null;
    content?: string | null;
    linkParameters: Array<{
      __typename?: 'LinkParameter';
      parameterId: number;
      parameter: {
        __typename?: 'Parameter';
        name: string;
        systemName: string;
        description?: string | null;
        sampleValue?: string | null;
        sysDataTypeId: SysDataTypeId;
        sysParameterTypeId: SysParameterTypeId;
        sysCategory?: string | null;
      };
    } | null>;
  } | null;
};

export type LinkPreviewByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type LinkPreviewByIdQuery = {
  __typename?: 'Query';
  linkPreviewById?: { __typename?: 'RenderedLink'; reference?: string | null; content?: string | null } | null;
};

export type LinkPreviewByContentParametersQueryVariables = Exact<{
  input?: InputMaybe<LinkPreviewByContentParametersInput>;
}>;

export type LinkPreviewByContentParametersQuery = {
  __typename?: 'Query';
  linkPreviewByContentParameters?: {
    __typename?: 'RenderedLink';
    reference?: string | null;
    content?: string | null;
  } | null;
};

export type UpdateLinkMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: LinkUpdateInput;
}>;

export type UpdateLinkMutation = {
  __typename?: 'Mutation';
  updateLink?: { __typename?: 'Link'; linkId: number } | null;
};

export type ValidateLinkInsertToContentQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  contentId: Scalars['Int']['input'];
}>;

export type ValidateLinkInsertToContentQuery = { __typename?: 'Query'; validateLinkInsertToContent?: string | null };

export type MasterTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type MasterTemplatesQuery = {
  __typename?: 'Query';
  allMasterTemplates?: Array<{
    __typename?: 'MasterTemplate';
    masterTemplateId: number;
    name: string;
    description?: string | null;
    htmlBody?: string | null;
    mjmlBody?: string | null;
  } | null> | null;
};

export type CreateMasterTemplateMutationVariables = Exact<{
  input: MasterTemplateCreateInput;
}>;

export type CreateMasterTemplateMutation = {
  __typename?: 'Mutation';
  createMasterTemplate?: { __typename?: 'MasterTemplate'; masterTemplateId: number } | null;
};

export type DeleteMasterTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteMasterTemplateMutation = { __typename?: 'Mutation'; deleteMasterTemplate: boolean };

export type MasterTemplateReferencesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type MasterTemplateReferencesQuery = {
  __typename?: 'Query';
  masterTemplateReferences?: {
    __typename?: 'MasterTemplateReferences';
    any: boolean;
    entryPoints?: Array<{ __typename?: 'EntryPoint'; name: string } | null> | null;
  } | null;
};

export type MasterTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MasterTemplateQuery = {
  __typename?: 'Query';
  masterTemplateById?: {
    __typename?: 'MasterTemplate';
    masterTemplateId: number;
    name: string;
    description?: string | null;
    htmlBody?: string | null;
    mjmlBody?: string | null;
  } | null;
};

export type UpdateMasterTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: MasterTemplateUpdateInput;
}>;

export type UpdateMasterTemplateMutation = {
  __typename?: 'Mutation';
  updateMasterTemplate?: { __typename?: 'MasterTemplate'; masterTemplateId: number } | null;
};

export type ParametersQueryVariables = Exact<{ [key: string]: never }>;

export type ParametersQuery = {
  __typename?: 'Query';
  allParameters?: Array<{
    __typename?: 'Parameter';
    parameterId: number;
    name: string;
    systemName: string;
    description?: string | null;
    queryStringName: string;
    sampleValue?: string | null;
    sysDataTypeId: SysDataTypeId;
    sysParameterTypeId: SysParameterTypeId;
    sysCategory?: string | null;
    enumItems?: Array<{ __typename?: 'ParameterEnum'; key: string; value: string; enabled: boolean }> | null;
  } | null> | null;
};

export type AllDeepParametersByTemplateIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type AllDeepParametersByTemplateIdQuery = {
  __typename?: 'Query';
  templateAllSubTemplateDecisionParameters?: Array<{
    __typename?: 'Parameter';
    parameterId: number;
    name: string;
    systemName: string;
    description?: string | null;
    queryStringName: string;
    sampleValue?: string | null;
    sysDataTypeId: SysDataTypeId;
    sysParameterTypeId: SysParameterTypeId;
    sysCategory?: string | null;
    enumItems?: Array<{ __typename?: 'ParameterEnum'; key: string; value: string; enabled: boolean }> | null;
  } | null> | null;
};

export type AssignCodeBlockParametersMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: CodeBlockAssignParametersInput;
}>;

export type AssignCodeBlockParametersMutation = {
  __typename?: 'Mutation';
  assignCodeBlockParameters?: { __typename?: 'CodeBlock'; codeBlockId: number } | null;
};

export type CreateParameterMutationVariables = Exact<{
  input: ParameterCreateInput;
}>;

export type CreateParameterMutation = {
  __typename?: 'Mutation';
  createParameter?: { __typename?: 'Parameter'; sampleValue?: string | null } | null;
};

export type DeleteParameterMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteParameterMutation = { __typename?: 'Mutation'; deleteParameter: boolean };

export type ParameterReferencesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ParameterReferencesQuery = {
  __typename?: 'Query';
  parameterReferences?: {
    __typename?: 'ParameterReferences';
    any: boolean;
    codeBlocks?: Array<{ __typename?: 'CodeBlock'; name: string } | null> | null;
    entryPoints?: Array<{ __typename?: 'EntryPoint'; name: string } | null> | null;
    subTemplates?: Array<{ __typename?: 'SubTemplate'; name: string } | null> | null;
  } | null;
};

export type ParameterQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ParameterQuery = {
  __typename?: 'Query';
  parameterById?: {
    __typename?: 'Parameter';
    parameterId: number;
    name: string;
    systemName: string;
    description?: string | null;
    queryStringDataTypeId: SysQueryStringDataTypeId;
    queryStringName: string;
    sampleValue?: string | null;
    sysDataTypeId: SysDataTypeId;
    sysParameterTypeId: SysParameterTypeId;
    sysValue?: string | null;
    sysCategory?: string | null;
    mandatory: boolean;
    anonymize: boolean;
    queryStringDataType?: { __typename?: 'SysQueryStringDataType'; name: string } | null;
    sysDataType?: { __typename?: 'SysDataType'; name: string } | null;
    sysParameterType?: { __typename?: 'SysParameterType'; name: string } | null;
    enumItems?: Array<{ __typename?: 'ParameterEnum'; key: string; value: string }> | null;
  } | null;
};

export type UpdateParameterMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ParameterUpdateInput;
}>;

export type UpdateParameterMutation = {
  __typename?: 'Mutation';
  updateParameter?: { __typename?: 'Parameter'; sampleValue?: string | null } | null;
};

export type CodeBlocksQueryVariables = Exact<{ [key: string]: never }>;

export type CodeBlocksQuery = {
  __typename?: 'Query';
  allCodeBlocks?: Array<{
    __typename?: 'CodeBlock';
    codeBlockId: number;
    name: string;
    systemName: string;
    description?: string | null;
    codeBlockParameters: Array<{
      __typename?: 'CodeBlockParameter';
      codeBlockParameterId: number;
      codeBlockId: number;
      parameterId: number;
    } | null>;
  } | null> | null;
};

export type CreateCodeBlockMutationVariables = Exact<{
  input: CodeBlockCreateInput;
}>;

export type CreateCodeBlockMutation = {
  __typename?: 'Mutation';
  createCodeBlock?: { __typename?: 'CodeBlock'; codeBlockId: number } | null;
};

export type DeleteCodeBlockMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCodeBlockMutation = { __typename?: 'Mutation'; deleteCodeBlock: boolean };

export type CodeBlockReferencesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CodeBlockReferencesQuery = {
  __typename?: 'Query';
  codeBlockReferences?: {
    __typename?: 'CodeBlockReferences';
    any: boolean;
    entryPoints?: Array<{ __typename?: 'EntryPoint'; name: string } | null> | null;
    subTemplates?: Array<{ __typename?: 'SubTemplate'; name: string } | null> | null;
  } | null;
};

export type CodeBlockQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CodeBlockQuery = {
  __typename?: 'Query';
  codeBlockById?: {
    __typename?: 'CodeBlock';
    codeBlockId: number;
    name: string;
    systemName: string;
    description?: string | null;
    codeBlockParameters: Array<{
      __typename?: 'CodeBlockParameter';
      codeBlockParameterId: number;
      codeBlockId: number;
      parameterId: number;
      parameter: {
        __typename?: 'Parameter';
        sysParameterTypeId: SysParameterTypeId;
        name: string;
        systemName: string;
        description?: string | null;
        sysParameterType?: {
          __typename?: 'SysParameterType';
          sysParameterTypeId: SysParameterTypeId;
          name: string;
        } | null;
        enumItems?: Array<{ __typename?: 'ParameterEnum'; enabled: boolean; key: string; value: string }> | null;
      };
    } | null>;
  } | null;
};

export type CodeBlockDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CodeBlockDetailQuery = {
  __typename?: 'Query';
  codeBlockById?: {
    __typename?: 'CodeBlock';
    codeBlockId: number;
    name: string;
    systemName: string;
    description?: string | null;
    jsBody?: string | null;
    codeBlockParameters: Array<{
      __typename?: 'CodeBlockParameter';
      codeBlockParameterId: number;
      codeBlockId: number;
      parameterId: number;
      parameter: {
        __typename?: 'Parameter';
        name: string;
        systemName: string;
        description?: string | null;
        sysParameterType?: {
          __typename?: 'SysParameterType';
          sysParameterTypeId: SysParameterTypeId;
          name: string;
        } | null;
        enumItems?: Array<{ __typename?: 'ParameterEnum'; enabled: boolean; key: string; value: string }> | null;
      };
    } | null>;
  } | null;
};

export type CodeBlockPreviewByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type CodeBlockPreviewByIdQuery = { __typename?: 'Query'; codeBlockPreviewById?: string | null };

export type CodeBlockPreviewByJsBodyQueryVariables = Exact<{
  jsBody?: InputMaybe<Scalars['String']['input']>;
}>;

export type CodeBlockPreviewByJsBodyQuery = { __typename?: 'Query'; codeBlockPreviewByJsBody?: string | null };

export type UpdateCodeBlockMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: CodeBlockUpdateInput;
}>;

export type UpdateCodeBlockMutation = {
  __typename?: 'Mutation';
  updateCodeBlock?: { __typename?: 'CodeBlock'; codeBlockId: number } | null;
};

export type UpdateCodeBlockContentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ContentUpdateInput;
}>;

export type UpdateCodeBlockContentMutation = {
  __typename?: 'Mutation';
  updateContent?: { __typename?: 'Content'; contentId: number } | null;
};

export type ValidateCodeBlockInsertToContentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  contentId: Scalars['Int']['input'];
}>;

export type ValidateCodeBlockInsertToContentQuery = { __typename?: 'Query'; validateCodeBlockInsertToContent: boolean };

export type SubTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type SubTemplatesQuery = {
  __typename?: 'Query';
  allSubTemplates?: Array<{
    __typename?: 'SubTemplate';
    subTemplateId: number;
    name: string;
    systemName: string;
    description?: string | null;
  } | null> | null;
};

export type UpdateSubTemplateConditionalUseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SubTemplateConditionalUseInput;
}>;

export type UpdateSubTemplateConditionalUseMutation = {
  __typename?: 'Mutation';
  updateSubTemplateConditionalUse?: { __typename?: 'SubTemplate'; subTemplateId: number } | null;
};

export type AssignSubTemplateParametersMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SubTemplateAssignParametersInput;
}>;

export type AssignSubTemplateParametersMutation = {
  __typename?: 'Mutation';
  assignSubTemplateParameters?: {
    __typename?: 'SubTemplate';
    subTemplateId: number;
    name: string;
    subTemplateParameters: Array<{
      __typename?: 'SubTemplateParameter';
      parameterId: number;
      isDecision: boolean;
    } | null>;
  } | null;
};

export type CreateSubTemplateMutationVariables = Exact<{
  input: SubTemplateCreateInput;
}>;

export type CreateSubTemplateMutation = {
  __typename?: 'Mutation';
  createSubTemplate?: { __typename?: 'SubTemplate'; subTemplateId: number } | null;
};

export type DeleteSubTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteSubTemplateMutation = { __typename?: 'Mutation'; deleteSubTemplate: boolean };

export type SubTemplateReferencesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubTemplateReferencesQuery = {
  __typename?: 'Query';
  subTemplateReferences?: {
    __typename?: 'SubTemplateReferences';
    any: boolean;
    entryPoints?: Array<{ __typename?: 'EntryPoint'; name: string } | null> | null;
  } | null;
};

export type SubTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubTemplateQuery = {
  __typename?: 'Query';
  subTemplateById?: {
    __typename?: 'SubTemplate';
    subTemplateId: number;
    name: string;
    systemName: string;
    description?: string | null;
    subTemplateParameters: Array<{
      __typename?: 'SubTemplateParameter';
      parameterId: number;
      isDecision: boolean;
      parameter?: {
        __typename?: 'Parameter';
        name: string;
        systemName: string;
        description?: string | null;
        sysDataTypeId: SysDataTypeId;
        sysParameterTypeId: SysParameterTypeId;
        enumItems?: Array<{ __typename?: 'ParameterEnum'; enabled: boolean; key: string; value: string }> | null;
      } | null;
    } | null>;
    conditionalUse?: {
      __typename?: 'SubTemplateConditionalUse';
      conditions: Array<{
        __typename?: 'SubTemplateConditionalUseCondition';
        parameterId: number;
        value: {
          __typename?: 'SubTemplateConditionalUseConditionValue';
          bool?: boolean | null;
          intFrom?: number | null;
          intTo?: number | null;
          dateFrom?: any | null;
          dateTo?: any | null;
          doubleFrom?: number | null;
          doubleTo?: number | null;
          enums?: Array<string | null> | null;
          string?: string | null;
        };
      } | null>;
    } | null;
  } | null;
};

export type SubTemplateDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubTemplateDetailQuery = {
  __typename?: 'Query';
  subTemplateById?: {
    __typename?: 'SubTemplate';
    subTemplateId: number;
    name: string;
    systemName: string;
    description?: string | null;
    defaultLanguage?: string | null;
    languagesUsingDefaultLanguage?: Array<string | null> | null;
    subTemplateParameters: Array<{
      __typename?: 'SubTemplateParameter';
      parameterId: number;
      isDecision: boolean;
      parameter?: {
        __typename?: 'Parameter';
        sysDataTypeId: SysDataTypeId;
        sysParameterTypeId: SysParameterTypeId;
        name: string;
        systemName: string;
        description?: string | null;
        sysCategory?: string | null;
        sysParameterType?: {
          __typename?: 'SysParameterType';
          sysParameterTypeId: SysParameterTypeId;
          name: string;
        } | null;
        enumItems?: Array<{ __typename?: 'ParameterEnum'; enabled: boolean; key: string; value: string }> | null;
      } | null;
    } | null>;
  } | null;
};

export type SubTemplatePreviewByContentIdParametersQueryVariables = Exact<{
  input?: InputMaybe<SubTemplatePreviewByContentIdParametersInput>;
}>;

export type SubTemplatePreviewByContentIdParametersQuery = {
  __typename?: 'Query';
  subTemplatePreviewByContentIdParameters?: string | null;
};

export type SubTemplateContentByParametersQueryVariables = Exact<{
  input: SubTemplateContentByParametersInput;
}>;

export type SubTemplateContentByParametersQuery = {
  __typename?: 'Query';
  subTemplateContentByParameters?: {
    __typename?: 'Content';
    contentId: number;
    body?: string | null;
    isEmpty: boolean;
  } | null;
};

export type UpdateSubTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: SubTemplateUpdateInput;
}>;

export type UpdateSubTemplateMutation = {
  __typename?: 'Mutation';
  updateSubTemplate?: {
    __typename?: 'SubTemplate';
    subTemplateId: number;
    defaultLanguage?: string | null;
    languagesUsingDefaultLanguage?: Array<string | null> | null;
  } | null;
};

export type UpdateSubTemplateContentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: ContentUpdateInput;
}>;

export type UpdateSubTemplateContentMutation = {
  __typename?: 'Mutation';
  updateContent?: { __typename?: 'Content'; contentId: number } | null;
};

export type ValidateSubTemplateInsertToContentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  contentId: Scalars['Int']['input'];
}>;

export type ValidateSubTemplateInsertToContentQuery = {
  __typename?: 'Query';
  validateSubTemplateInsertToContent?: string | null;
};

export type WorkflowsQueryVariables = Exact<{ [key: string]: never }>;

export type WorkflowsQuery = {
  __typename?: 'Query';
  allWorkflows?: Array<{
    __typename?: 'Workflow';
    workflowId: number;
    name: string;
    description?: string | null;
    extData?: string | null;
    email: boolean;
    notification: boolean;
    sms: boolean;
  } | null> | null;
};

export type CreateWorkflowMutationVariables = Exact<{
  input: WorkflowCreateInput;
}>;

export type CreateWorkflowMutation = {
  __typename?: 'Mutation';
  createWorkflow?: { __typename?: 'Workflow'; workflowId: number } | null;
};

export type DeleteWorkflowMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteWorkflowMutation = { __typename?: 'Mutation'; deleteWorkflow: boolean };

export type WorkflowReferencesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type WorkflowReferencesQuery = {
  __typename?: 'Query';
  workflowReferences?: {
    __typename?: 'WorkflowReferences';
    any: boolean;
    entryPoints?: Array<{ __typename?: 'EntryPoint'; name: string } | null> | null;
  } | null;
};

export type WorkflowQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type WorkflowQuery = {
  __typename?: 'Query';
  workflowById?: {
    __typename?: 'Workflow';
    workflowId: number;
    name: string;
    description?: string | null;
    extData?: string | null;
    email: boolean;
    notification: boolean;
    sms: boolean;
  } | null;
};

export type UpdateWorkflowMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: WorkflowUpdateInput;
}>;

export type UpdateWorkflowMutation = {
  __typename?: 'Mutation';
  updateWorkflow?: { __typename?: 'Workflow'; workflowId: number } | null;
};

export const EntryPointsDocument = `
    query EntryPoints {
  allEntryPoints {
    entryPointId
    name
    description
    masterTemplate {
      name
    }
    workflow {
      name
    }
  }
}
    `;
export const EntryPointStatisticsBasicDocument = `
    query EntryPointStatisticsBasic {
  allEntryPointStatisticsBasic {
    entryPointId
    name
    description
    maxContentCount
    missingContentCount
  }
}
    `;
export const EntryPointStatisticsBasic2Document = `
    query EntryPointStatisticsBasic2 {
  allEntryPointStatisticsBasic {
    entryPointId
    maxContentCount
    missingContentCount
  }
}
    `;
export const AssignEntryPointParametersDocument = `
    mutation AssignEntryPointParameters($id: ID!, $input: EntryPointAssignParametersInput!) {
  assignEntryPointParameters(id: $id, input: $input) {
    entryPointId
  }
}
    `;
export const ClearContentDocument = `
    mutation ClearContent($id: ID!) {
  clearContent(id: $id) {
    isEmpty
    body
  }
}
    `;
export const CreateEntryPointDocument = `
    mutation CreateEntryPoint($input: EntryPointCreateInput!) {
  createEntryPoint(input: $input) {
    entryPointId
  }
}
    `;
export const DeleteEntryPointDocument = `
    mutation DeleteEntryPoint($id: ID!) {
  deleteEntryPoint(id: $id)
}
    `;
export const EntryPointDocument = `
    query EntryPoint($id: ID!) {
  entryPointById(id: $id) {
    entryPointId
    name
    description
    queryStringName
    workflowId
    masterTemplateId
    entryPointParameters {
      parameterId
      isDecision
      parameter {
        name
        systemName
        sampleValue
        description
        sysDataTypeId
        sysParameterTypeId
      }
    }
  }
}
    `;
export const EntryPointDetailDocument = `
    query EntryPointDetail($id: ID!) {
  entryPointById(id: $id) {
    entryPointId
    name
    description
    queryStringName
    entryPointParameters {
      entryPointParameterId
      entryPointId
      parameterId
      isDecision
      parameter {
        parameterId
        sysDataTypeId
        sysParameterTypeId
        sysParameterType {
          sysParameterTypeId
          name
        }
        name
        systemName
        sampleValue
        description
        enumItems {
          enabled
          key
          value
        }
        sysCategory
      }
    }
  }
}
    `;
export const EntryPointCurrentTemplateIdDocument = `
    query EntryPointCurrentTemplateId($id: ID!) {
  entryPointCurrentTemplateId(id: $id)
}
    `;
export const EntryPointContentMatrixDocument = `
    query EntryPointContentMatrix($id: Int!, $input: EntryPointMatrixInput!) {
  entryPointContentMatrix(id: $id, input: $input) {
    contentId
    subject
    body
    parameterValues {
      key
      value
    }
  }
}
    `;
export const TemplatePreviewByContentIdParametersDocument = `
    query TemplatePreviewByContentIdParameters($input: TemplatePreviewByContentIdParametersInput) {
  templatePreviewByContentIdParameters(input: $input) {
    subject
    body
  }
}
    `;
export const TemplatePreviewByContentParametersDocument = `
    query TemplatePreviewByContentParameters($input: TemplatePreviewByContentParametersInput) {
  templatePreviewByContentParameters(input: $input) {
    subject
    body
  }
}
    `;
export const SendTemplatePreviewByContentIdParametersDocument = `
    mutation SendTemplatePreviewByContentIdParameters($input: SendTemplatePreviewByContentIdParametersInput!) {
  sendTemplatePreviewByContentIdParameters(input: $input)
}
    `;
export const PublishEntryPointDocument = `
    mutation PublishEntryPoint($id: ID!) {
  publishEntryPoint(id: $id)
}
    `;
export const EntryPointStatisticsDetailDocument = `
    query EntryPointStatisticsDetail($id: ID!, $input: EntryPointStatisticsDetailInput) {
  entryPointStatisticsDetail(id: $id, input: $input) {
    name
    parameterId
    values {
      maxContentCount
      missingContentCount
      name
      value
    }
  }
}
    `;
export const TemplateContentByParametersDocument = `
    query TemplateContentByParameters($input: TemplateContentByParametersInput!) {
  templateContentByParameters(input: $input) {
    contentId
    subject
    body
    isEmpty
  }
}
    `;
export const TemplateByIdDocument = `
    query TemplateById($id: ID!) {
  templateById(id: $id) {
    templateId
    entryPointId
    version
    extData
    entryPoint {
      entryPointId
      masterTemplateId
      workflowId
      name
    }
    defaultLanguage
    languagesUsingDefaultLanguage
  }
}
    `;
export const UpdateTemplateDocument = `
    mutation UpdateTemplate($id: ID!, $input: TemplateUpdateInput!) {
  updateTemplate(id: $id, input: $input) {
    defaultLanguage
    languagesUsingDefaultLanguage
  }
}
    `;
export const UpdateEntryPointDocument = `
    mutation UpdateEntryPoint($id: ID!, $input: EntryPointUpdateInput!) {
  updateEntryPoint(id: $id, input: $input) {
    entryPointId
  }
}
    `;
export const UpdateEntryPointContentDocument = `
    mutation UpdateEntryPointContent($id: ID!, $input: ContentUpdateInput!) {
  updateContent(id: $id, input: $input) {
    contentId
  }
}
    `;
export const ValidateEntryPointDocument = `
    query ValidateEntryPoint($id: ID!) {
  validateEntryPoint(id: $id)
}
    `;
export const ImagesDocument = `
    query Images {
  allImages {
    imageId
    name
    description
    systemName
  }
}
    `;
export const CreateImageDocument = `
    mutation CreateImage($input: ImageCreateInput!) {
  createImage(input: $input) {
    imageId
  }
}
    `;
export const DeleteImageDocument = `
    mutation DeleteImage($id: Int!) {
  deleteImage(id: $id)
}
    `;
export const ImageReferencesDocument = `
    query ImageReferences($id: Int!) {
  imageReferences(id: $id) {
    entryPoints {
      name
    }
    subTemplates {
      name
    }
    links {
      name
    }
    any
  }
}
    `;
export const ImageDocument = `
    query Image($id: Int!) {
  imageById(id: $id) {
    imageId
    name
    description
    alternateText
    width
    height
    source
  }
}
    `;
export const UpdateImageDocument = `
    mutation UpdateImage($id: Int!, $input: ImageUpdateInput!) {
  updateImage(id: $id, input: $input) {
    imageId
  }
}
    `;
export const LinksDocument = `
    query Links {
  allLinks {
    linkId
    name
    description
    systemName
  }
}
    `;
export const AssignLinkParametersDocument = `
    mutation AssignLinkParameters($id: ID!, $input: LinkAssignParametersInput!) {
  assignLinkParameters(id: $id, input: $input) {
    linkId
  }
}
    `;
export const CreateLinkDocument = `
    mutation CreateLink($input: LinkCreateInput!) {
  createLink(input: $input) {
    linkId
  }
}
    `;
export const DeleteLinkDocument = `
    mutation DeleteLink($id: Int!) {
  deleteLink(id: $id)
}
    `;
export const LinkReferencesDocument = `
    query LinkReferences($id: Int!) {
  linkReferences(id: $id) {
    entryPoints {
      name
    }
    subTemplates {
      name
    }
    any
  }
}
    `;
export const LinkDocument = `
    query Link($id: Int!) {
  linkById(id: $id) {
    linkId
    name
    description
    reference
    content
    linkParameters {
      parameterId
      parameter {
        name
        systemName
        description
        sampleValue
        systemName
        sampleValue
        description
        sysDataTypeId
        sysParameterTypeId
        sysCategory
      }
    }
  }
}
    `;
export const LinkPreviewByIdDocument = `
    query LinkPreviewById($id: Int!) {
  linkPreviewById(id: $id) {
    reference
    content
  }
}
    `;
export const LinkPreviewByContentParametersDocument = `
    query linkPreviewByContentParameters($input: LinkPreviewByContentParametersInput) {
  linkPreviewByContentParameters(input: $input) {
    reference
    content
  }
}
    `;
export const UpdateLinkDocument = `
    mutation UpdateLink($id: Int!, $input: LinkUpdateInput!) {
  updateLink(id: $id, input: $input) {
    linkId
  }
}
    `;
export const ValidateLinkInsertToContentDocument = `
    query ValidateLinkInsertToContent($id: Int!, $contentId: Int!) {
  validateLinkInsertToContent(id: $id, contentId: $contentId)
}
    `;
export const MasterTemplatesDocument = `
    query MasterTemplates {
  allMasterTemplates {
    masterTemplateId
    name
    description
    htmlBody
    mjmlBody
  }
}
    `;
export const CreateMasterTemplateDocument = `
    mutation CreateMasterTemplate($input: MasterTemplateCreateInput!) {
  createMasterTemplate(input: $input) {
    masterTemplateId
  }
}
    `;
export const DeleteMasterTemplateDocument = `
    mutation DeleteMasterTemplate($id: ID!) {
  deleteMasterTemplate(id: $id)
}
    `;
export const MasterTemplateReferencesDocument = `
    query MasterTemplateReferences($id: Int!) {
  masterTemplateReferences(id: $id) {
    entryPoints {
      name
    }
    any
  }
}
    `;
export const MasterTemplateDocument = `
    query MasterTemplate($id: ID!) {
  masterTemplateById(id: $id) {
    masterTemplateId
    name
    description
    htmlBody
    mjmlBody
  }
}
    `;
export const UpdateMasterTemplateDocument = `
    mutation UpdateMasterTemplate($id: ID!, $input: MasterTemplateUpdateInput!) {
  updateMasterTemplate(id: $id, input: $input) {
    masterTemplateId
  }
}
    `;
export const ParametersDocument = `
    query Parameters {
  allParameters {
    parameterId
    name
    systemName
    description
    queryStringName
    sampleValue
    sysDataTypeId
    sysParameterTypeId
    enumItems {
      key
      value
      enabled
    }
    sysCategory
  }
}
    `;
export const AllDeepParametersByTemplateIdDocument = `
    query AllDeepParametersByTemplateId($id: Int!) {
  templateAllSubTemplateDecisionParameters(id: $id) {
    parameterId
    name
    systemName
    description
    queryStringName
    sampleValue
    sysDataTypeId
    sysParameterTypeId
    enumItems {
      key
      value
      enabled
    }
    sysCategory
  }
}
    `;
export const AssignCodeBlockParametersDocument = `
    mutation AssignCodeBlockParameters($id: ID!, $input: CodeBlockAssignParametersInput!) {
  assignCodeBlockParameters(id: $id, input: $input) {
    codeBlockId
  }
}
    `;
export const CreateParameterDocument = `
    mutation CreateParameter($input: ParameterCreateInput!) {
  createParameter(input: $input) {
    sampleValue
  }
}
    `;
export const DeleteParameterDocument = `
    mutation DeleteParameter($id: ID!) {
  deleteParameter(id: $id)
}
    `;
export const ParameterReferencesDocument = `
    query ParameterReferences($id: ID!) {
  parameterReferences(id: $id) {
    codeBlocks {
      name
    }
    entryPoints {
      name
    }
    subTemplates {
      name
    }
    any
  }
}
    `;
export const ParameterDocument = `
    query Parameter($id: ID!) {
  parameterById(id: $id) {
    parameterId
    name
    systemName
    description
    queryStringDataType {
      name
    }
    queryStringDataTypeId
    queryStringName
    sampleValue
    sysDataType {
      name
    }
    sysDataTypeId
    sysParameterType {
      name
    }
    sysParameterTypeId
    sysValue
    enumItems {
      key
      value
    }
    sysCategory
    mandatory
    anonymize
  }
}
    `;
export const UpdateParameterDocument = `
    mutation UpdateParameter($id: ID!, $input: ParameterUpdateInput!) {
  updateParameter(id: $id, input: $input) {
    sampleValue
  }
}
    `;
export const CodeBlocksDocument = `
    query CodeBlocks {
  allCodeBlocks {
    codeBlockId
    name
    systemName
    description
    codeBlockParameters {
      codeBlockParameterId
      codeBlockId
      parameterId
    }
  }
}
    `;
export const CreateCodeBlockDocument = `
    mutation CreateCodeBlock($input: CodeBlockCreateInput!) {
  createCodeBlock(input: $input) {
    codeBlockId
  }
}
    `;
export const DeleteCodeBlockDocument = `
    mutation DeleteCodeBlock($id: ID!) {
  deleteCodeBlock(id: $id)
}
    `;
export const CodeBlockReferencesDocument = `
    query CodeBlockReferences($id: ID!) {
  codeBlockReferences(id: $id) {
    entryPoints {
      name
    }
    subTemplates {
      name
    }
    any
  }
}
    `;
export const CodeBlockDocument = `
    query CodeBlock($id: ID!) {
  codeBlockById(id: $id) {
    codeBlockId
    name
    systemName
    description
    codeBlockParameters {
      codeBlockParameterId
      codeBlockId
      parameterId
      parameter {
        sysParameterTypeId
        sysParameterType {
          sysParameterTypeId
          name
        }
        name
        systemName
        description
        enumItems {
          enabled
          key
          value
        }
      }
    }
  }
}
    `;
export const CodeBlockDetailDocument = `
    query CodeBlockDetail($id: ID!) {
  codeBlockById(id: $id) {
    codeBlockId
    name
    systemName
    description
    jsBody
    codeBlockParameters {
      codeBlockParameterId
      codeBlockId
      parameterId
      parameter {
        sysParameterType {
          sysParameterTypeId
          name
        }
        name
        systemName
        description
        enumItems {
          enabled
          key
          value
        }
      }
    }
  }
}
    `;
export const CodeBlockPreviewByIdDocument = `
    query CodeBlockPreviewById($id: ID!) {
  codeBlockPreviewById(id: $id)
}
    `;
export const CodeBlockPreviewByJsBodyDocument = `
    query CodeBlockPreviewByJsBody($jsBody: String) {
  codeBlockPreviewByJsBody(jsBody: $jsBody)
}
    `;
export const UpdateCodeBlockDocument = `
    mutation UpdateCodeBlock($id: ID!, $input: CodeBlockUpdateInput!) {
  updateCodeBlock(id: $id, input: $input) {
    codeBlockId
  }
}
    `;
export const UpdateCodeBlockContentDocument = `
    mutation UpdateCodeBlockContent($id: ID!, $input: ContentUpdateInput!) {
  updateContent(id: $id, input: $input) {
    contentId
  }
}
    `;
export const ValidateCodeBlockInsertToContentDocument = `
    query ValidateCodeBlockInsertToContent($id: ID!, $contentId: Int!) {
  validateCodeBlockInsertToContent(id: $id, contentId: $contentId)
}
    `;
export const SubTemplatesDocument = `
    query SubTemplates {
  allSubTemplates {
    subTemplateId
    name
    systemName
    description
  }
}
    `;
export const UpdateSubTemplateConditionalUseDocument = `
    mutation UpdateSubTemplateConditionalUse($id: ID!, $input: SubTemplateConditionalUseInput!) {
  updateSubTemplateConditionalUse(id: $id, input: $input) {
    subTemplateId
  }
}
    `;
export const AssignSubTemplateParametersDocument = `
    mutation AssignSubTemplateParameters($id: ID!, $input: SubTemplateAssignParametersInput!) {
  assignSubTemplateParameters(id: $id, input: $input) {
    subTemplateId
    name
    subTemplateParameters {
      parameterId
      isDecision
    }
  }
}
    `;
export const CreateSubTemplateDocument = `
    mutation CreateSubTemplate($input: SubTemplateCreateInput!) {
  createSubTemplate(input: $input) {
    subTemplateId
  }
}
    `;
export const DeleteSubTemplateDocument = `
    mutation DeleteSubTemplate($id: ID!) {
  deleteSubTemplate(id: $id)
}
    `;
export const SubTemplateReferencesDocument = `
    query SubTemplateReferences($id: ID!) {
  subTemplateReferences(id: $id) {
    entryPoints {
      name
    }
    any
  }
}
    `;
export const SubTemplateDocument = `
    query SubTemplate($id: ID!) {
  subTemplateById(id: $id) {
    subTemplateId
    name
    systemName
    description
    subTemplateParameters {
      parameterId
      isDecision
      parameter {
        name
        systemName
        description
        sysDataTypeId
        sysParameterTypeId
        enumItems {
          enabled
          key
          value
        }
      }
    }
    conditionalUse {
      conditions {
        parameterId
        value {
          bool
          intFrom
          intTo
          dateFrom
          dateTo
          doubleFrom
          doubleTo
          enums
          string
        }
      }
    }
  }
}
    `;
export const SubTemplateDetailDocument = `
    query SubTemplateDetail($id: ID!) {
  subTemplateById(id: $id) {
    subTemplateId
    name
    systemName
    description
    defaultLanguage
    languagesUsingDefaultLanguage
    subTemplateParameters {
      parameterId
      isDecision
      parameter {
        sysDataTypeId
        sysParameterTypeId
        sysParameterType {
          sysParameterTypeId
          name
        }
        name
        systemName
        description
        enumItems {
          enabled
          key
          value
        }
        sysCategory
      }
    }
  }
}
    `;
export const SubTemplatePreviewByContentIdParametersDocument = `
    query SubTemplatePreviewByContentIdParameters($input: SubTemplatePreviewByContentIdParametersInput) {
  subTemplatePreviewByContentIdParameters(input: $input)
}
    `;
export const SubTemplateContentByParametersDocument = `
    query SubTemplateContentByParameters($input: SubTemplateContentByParametersInput!) {
  subTemplateContentByParameters(input: $input) {
    contentId
    body
    isEmpty
  }
}
    `;
export const UpdateSubTemplateDocument = `
    mutation UpdateSubTemplate($id: ID!, $input: SubTemplateUpdateInput!) {
  updateSubTemplate(id: $id, input: $input) {
    subTemplateId
    defaultLanguage
    languagesUsingDefaultLanguage
  }
}
    `;
export const UpdateSubTemplateContentDocument = `
    mutation UpdateSubTemplateContent($id: ID!, $input: ContentUpdateInput!) {
  updateContent(id: $id, input: $input) {
    contentId
  }
}
    `;
export const ValidateSubTemplateInsertToContentDocument = `
    query ValidateSubTemplateInsertToContent($id: ID!, $contentId: Int!) {
  validateSubTemplateInsertToContent(id: $id, contentId: $contentId)
}
    `;
export const WorkflowsDocument = `
    query Workflows {
  allWorkflows {
    workflowId
    name
    description
    extData
    email
    notification
    sms
  }
}
    `;
export const CreateWorkflowDocument = `
    mutation CreateWorkflow($input: WorkflowCreateInput!) {
  createWorkflow(input: $input) {
    workflowId
  }
}
    `;
export const DeleteWorkflowDocument = `
    mutation DeleteWorkflow($id: ID!) {
  deleteWorkflow(id: $id)
}
    `;
export const WorkflowReferencesDocument = `
    query WorkflowReferences($id: ID!) {
  workflowReferences(id: $id) {
    entryPoints {
      name
    }
    any
  }
}
    `;
export const WorkflowDocument = `
    query Workflow($id: ID!) {
  workflowById(id: $id) {
    workflowId
    name
    description
    extData
    email
    notification
    sms
  }
}
    `;
export const UpdateWorkflowDocument = `
    mutation UpdateWorkflow($id: ID!, $input: WorkflowUpdateInput!) {
  updateWorkflow(id: $id, input: $input) {
    workflowId
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    EntryPoints: build.query<EntryPointsQuery, EntryPointsQueryVariables | void>({
      query: (variables) => ({ document: EntryPointsDocument, variables }),
    }),
    EntryPointStatisticsBasic: build.query<
      EntryPointStatisticsBasicQuery,
      EntryPointStatisticsBasicQueryVariables | void
    >({
      query: (variables) => ({ document: EntryPointStatisticsBasicDocument, variables }),
    }),
    EntryPointStatisticsBasic2: build.query<
      EntryPointStatisticsBasic2Query,
      EntryPointStatisticsBasic2QueryVariables | void
    >({
      query: (variables) => ({ document: EntryPointStatisticsBasic2Document, variables }),
    }),
    AssignEntryPointParameters: build.mutation<
      AssignEntryPointParametersMutation,
      AssignEntryPointParametersMutationVariables
    >({
      query: (variables) => ({ document: AssignEntryPointParametersDocument, variables }),
    }),
    ClearContent: build.mutation<ClearContentMutation, ClearContentMutationVariables>({
      query: (variables) => ({ document: ClearContentDocument, variables }),
    }),
    CreateEntryPoint: build.mutation<CreateEntryPointMutation, CreateEntryPointMutationVariables>({
      query: (variables) => ({ document: CreateEntryPointDocument, variables }),
    }),
    DeleteEntryPoint: build.mutation<DeleteEntryPointMutation, DeleteEntryPointMutationVariables>({
      query: (variables) => ({ document: DeleteEntryPointDocument, variables }),
    }),
    EntryPoint: build.query<EntryPointQuery, EntryPointQueryVariables>({
      query: (variables) => ({ document: EntryPointDocument, variables }),
    }),
    EntryPointDetail: build.query<EntryPointDetailQuery, EntryPointDetailQueryVariables>({
      query: (variables) => ({ document: EntryPointDetailDocument, variables }),
    }),
    EntryPointCurrentTemplateId: build.query<
      EntryPointCurrentTemplateIdQuery,
      EntryPointCurrentTemplateIdQueryVariables
    >({
      query: (variables) => ({ document: EntryPointCurrentTemplateIdDocument, variables }),
    }),
    EntryPointContentMatrix: build.query<EntryPointContentMatrixQuery, EntryPointContentMatrixQueryVariables>({
      query: (variables) => ({ document: EntryPointContentMatrixDocument, variables }),
    }),
    TemplatePreviewByContentIdParameters: build.query<
      TemplatePreviewByContentIdParametersQuery,
      TemplatePreviewByContentIdParametersQueryVariables | void
    >({
      query: (variables) => ({ document: TemplatePreviewByContentIdParametersDocument, variables }),
    }),
    TemplatePreviewByContentParameters: build.query<
      TemplatePreviewByContentParametersQuery,
      TemplatePreviewByContentParametersQueryVariables | void
    >({
      query: (variables) => ({ document: TemplatePreviewByContentParametersDocument, variables }),
    }),
    SendTemplatePreviewByContentIdParameters: build.mutation<
      SendTemplatePreviewByContentIdParametersMutation,
      SendTemplatePreviewByContentIdParametersMutationVariables
    >({
      query: (variables) => ({ document: SendTemplatePreviewByContentIdParametersDocument, variables }),
    }),
    PublishEntryPoint: build.mutation<PublishEntryPointMutation, PublishEntryPointMutationVariables>({
      query: (variables) => ({ document: PublishEntryPointDocument, variables }),
    }),
    EntryPointStatisticsDetail: build.query<EntryPointStatisticsDetailQuery, EntryPointStatisticsDetailQueryVariables>({
      query: (variables) => ({ document: EntryPointStatisticsDetailDocument, variables }),
    }),
    TemplateContentByParameters: build.query<
      TemplateContentByParametersQuery,
      TemplateContentByParametersQueryVariables
    >({
      query: (variables) => ({ document: TemplateContentByParametersDocument, variables }),
    }),
    TemplateById: build.query<TemplateByIdQuery, TemplateByIdQueryVariables>({
      query: (variables) => ({ document: TemplateByIdDocument, variables }),
    }),
    UpdateTemplate: build.mutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>({
      query: (variables) => ({ document: UpdateTemplateDocument, variables }),
    }),
    UpdateEntryPoint: build.mutation<UpdateEntryPointMutation, UpdateEntryPointMutationVariables>({
      query: (variables) => ({ document: UpdateEntryPointDocument, variables }),
    }),
    UpdateEntryPointContent: build.mutation<UpdateEntryPointContentMutation, UpdateEntryPointContentMutationVariables>({
      query: (variables) => ({ document: UpdateEntryPointContentDocument, variables }),
    }),
    ValidateEntryPoint: build.query<ValidateEntryPointQuery, ValidateEntryPointQueryVariables>({
      query: (variables) => ({ document: ValidateEntryPointDocument, variables }),
    }),
    Images: build.query<ImagesQuery, ImagesQueryVariables | void>({
      query: (variables) => ({ document: ImagesDocument, variables }),
    }),
    CreateImage: build.mutation<CreateImageMutation, CreateImageMutationVariables>({
      query: (variables) => ({ document: CreateImageDocument, variables }),
    }),
    DeleteImage: build.mutation<DeleteImageMutation, DeleteImageMutationVariables>({
      query: (variables) => ({ document: DeleteImageDocument, variables }),
    }),
    ImageReferences: build.query<ImageReferencesQuery, ImageReferencesQueryVariables>({
      query: (variables) => ({ document: ImageReferencesDocument, variables }),
    }),
    Image: build.query<ImageQuery, ImageQueryVariables>({
      query: (variables) => ({ document: ImageDocument, variables }),
    }),
    UpdateImage: build.mutation<UpdateImageMutation, UpdateImageMutationVariables>({
      query: (variables) => ({ document: UpdateImageDocument, variables }),
    }),
    Links: build.query<LinksQuery, LinksQueryVariables | void>({
      query: (variables) => ({ document: LinksDocument, variables }),
    }),
    AssignLinkParameters: build.mutation<AssignLinkParametersMutation, AssignLinkParametersMutationVariables>({
      query: (variables) => ({ document: AssignLinkParametersDocument, variables }),
    }),
    CreateLink: build.mutation<CreateLinkMutation, CreateLinkMutationVariables>({
      query: (variables) => ({ document: CreateLinkDocument, variables }),
    }),
    DeleteLink: build.mutation<DeleteLinkMutation, DeleteLinkMutationVariables>({
      query: (variables) => ({ document: DeleteLinkDocument, variables }),
    }),
    LinkReferences: build.query<LinkReferencesQuery, LinkReferencesQueryVariables>({
      query: (variables) => ({ document: LinkReferencesDocument, variables }),
    }),
    Link: build.query<LinkQuery, LinkQueryVariables>({
      query: (variables) => ({ document: LinkDocument, variables }),
    }),
    LinkPreviewById: build.query<LinkPreviewByIdQuery, LinkPreviewByIdQueryVariables>({
      query: (variables) => ({ document: LinkPreviewByIdDocument, variables }),
    }),
    linkPreviewByContentParameters: build.query<
      LinkPreviewByContentParametersQuery,
      LinkPreviewByContentParametersQueryVariables | void
    >({
      query: (variables) => ({ document: LinkPreviewByContentParametersDocument, variables }),
    }),
    UpdateLink: build.mutation<UpdateLinkMutation, UpdateLinkMutationVariables>({
      query: (variables) => ({ document: UpdateLinkDocument, variables }),
    }),
    ValidateLinkInsertToContent: build.query<
      ValidateLinkInsertToContentQuery,
      ValidateLinkInsertToContentQueryVariables
    >({
      query: (variables) => ({ document: ValidateLinkInsertToContentDocument, variables }),
    }),
    MasterTemplates: build.query<MasterTemplatesQuery, MasterTemplatesQueryVariables | void>({
      query: (variables) => ({ document: MasterTemplatesDocument, variables }),
    }),
    CreateMasterTemplate: build.mutation<CreateMasterTemplateMutation, CreateMasterTemplateMutationVariables>({
      query: (variables) => ({ document: CreateMasterTemplateDocument, variables }),
    }),
    DeleteMasterTemplate: build.mutation<DeleteMasterTemplateMutation, DeleteMasterTemplateMutationVariables>({
      query: (variables) => ({ document: DeleteMasterTemplateDocument, variables }),
    }),
    MasterTemplateReferences: build.query<MasterTemplateReferencesQuery, MasterTemplateReferencesQueryVariables>({
      query: (variables) => ({ document: MasterTemplateReferencesDocument, variables }),
    }),
    MasterTemplate: build.query<MasterTemplateQuery, MasterTemplateQueryVariables>({
      query: (variables) => ({ document: MasterTemplateDocument, variables }),
    }),
    UpdateMasterTemplate: build.mutation<UpdateMasterTemplateMutation, UpdateMasterTemplateMutationVariables>({
      query: (variables) => ({ document: UpdateMasterTemplateDocument, variables }),
    }),
    Parameters: build.query<ParametersQuery, ParametersQueryVariables | void>({
      query: (variables) => ({ document: ParametersDocument, variables }),
    }),
    AllDeepParametersByTemplateId: build.query<
      AllDeepParametersByTemplateIdQuery,
      AllDeepParametersByTemplateIdQueryVariables
    >({
      query: (variables) => ({ document: AllDeepParametersByTemplateIdDocument, variables }),
    }),
    AssignCodeBlockParameters: build.mutation<
      AssignCodeBlockParametersMutation,
      AssignCodeBlockParametersMutationVariables
    >({
      query: (variables) => ({ document: AssignCodeBlockParametersDocument, variables }),
    }),
    CreateParameter: build.mutation<CreateParameterMutation, CreateParameterMutationVariables>({
      query: (variables) => ({ document: CreateParameterDocument, variables }),
    }),
    DeleteParameter: build.mutation<DeleteParameterMutation, DeleteParameterMutationVariables>({
      query: (variables) => ({ document: DeleteParameterDocument, variables }),
    }),
    ParameterReferences: build.query<ParameterReferencesQuery, ParameterReferencesQueryVariables>({
      query: (variables) => ({ document: ParameterReferencesDocument, variables }),
    }),
    Parameter: build.query<ParameterQuery, ParameterQueryVariables>({
      query: (variables) => ({ document: ParameterDocument, variables }),
    }),
    UpdateParameter: build.mutation<UpdateParameterMutation, UpdateParameterMutationVariables>({
      query: (variables) => ({ document: UpdateParameterDocument, variables }),
    }),
    CodeBlocks: build.query<CodeBlocksQuery, CodeBlocksQueryVariables | void>({
      query: (variables) => ({ document: CodeBlocksDocument, variables }),
    }),
    CreateCodeBlock: build.mutation<CreateCodeBlockMutation, CreateCodeBlockMutationVariables>({
      query: (variables) => ({ document: CreateCodeBlockDocument, variables }),
    }),
    DeleteCodeBlock: build.mutation<DeleteCodeBlockMutation, DeleteCodeBlockMutationVariables>({
      query: (variables) => ({ document: DeleteCodeBlockDocument, variables }),
    }),
    CodeBlockReferences: build.query<CodeBlockReferencesQuery, CodeBlockReferencesQueryVariables>({
      query: (variables) => ({ document: CodeBlockReferencesDocument, variables }),
    }),
    CodeBlock: build.query<CodeBlockQuery, CodeBlockQueryVariables>({
      query: (variables) => ({ document: CodeBlockDocument, variables }),
    }),
    CodeBlockDetail: build.query<CodeBlockDetailQuery, CodeBlockDetailQueryVariables>({
      query: (variables) => ({ document: CodeBlockDetailDocument, variables }),
    }),
    CodeBlockPreviewById: build.query<CodeBlockPreviewByIdQuery, CodeBlockPreviewByIdQueryVariables>({
      query: (variables) => ({ document: CodeBlockPreviewByIdDocument, variables }),
    }),
    CodeBlockPreviewByJsBody: build.query<CodeBlockPreviewByJsBodyQuery, CodeBlockPreviewByJsBodyQueryVariables | void>(
      {
        query: (variables) => ({ document: CodeBlockPreviewByJsBodyDocument, variables }),
      },
    ),
    UpdateCodeBlock: build.mutation<UpdateCodeBlockMutation, UpdateCodeBlockMutationVariables>({
      query: (variables) => ({ document: UpdateCodeBlockDocument, variables }),
    }),
    UpdateCodeBlockContent: build.mutation<UpdateCodeBlockContentMutation, UpdateCodeBlockContentMutationVariables>({
      query: (variables) => ({ document: UpdateCodeBlockContentDocument, variables }),
    }),
    ValidateCodeBlockInsertToContent: build.query<
      ValidateCodeBlockInsertToContentQuery,
      ValidateCodeBlockInsertToContentQueryVariables
    >({
      query: (variables) => ({ document: ValidateCodeBlockInsertToContentDocument, variables }),
    }),
    SubTemplates: build.query<SubTemplatesQuery, SubTemplatesQueryVariables | void>({
      query: (variables) => ({ document: SubTemplatesDocument, variables }),
    }),
    UpdateSubTemplateConditionalUse: build.mutation<
      UpdateSubTemplateConditionalUseMutation,
      UpdateSubTemplateConditionalUseMutationVariables
    >({
      query: (variables) => ({ document: UpdateSubTemplateConditionalUseDocument, variables }),
    }),
    AssignSubTemplateParameters: build.mutation<
      AssignSubTemplateParametersMutation,
      AssignSubTemplateParametersMutationVariables
    >({
      query: (variables) => ({ document: AssignSubTemplateParametersDocument, variables }),
    }),
    CreateSubTemplate: build.mutation<CreateSubTemplateMutation, CreateSubTemplateMutationVariables>({
      query: (variables) => ({ document: CreateSubTemplateDocument, variables }),
    }),
    DeleteSubTemplate: build.mutation<DeleteSubTemplateMutation, DeleteSubTemplateMutationVariables>({
      query: (variables) => ({ document: DeleteSubTemplateDocument, variables }),
    }),
    SubTemplateReferences: build.query<SubTemplateReferencesQuery, SubTemplateReferencesQueryVariables>({
      query: (variables) => ({ document: SubTemplateReferencesDocument, variables }),
    }),
    SubTemplate: build.query<SubTemplateQuery, SubTemplateQueryVariables>({
      query: (variables) => ({ document: SubTemplateDocument, variables }),
    }),
    SubTemplateDetail: build.query<SubTemplateDetailQuery, SubTemplateDetailQueryVariables>({
      query: (variables) => ({ document: SubTemplateDetailDocument, variables }),
    }),
    SubTemplatePreviewByContentIdParameters: build.query<
      SubTemplatePreviewByContentIdParametersQuery,
      SubTemplatePreviewByContentIdParametersQueryVariables | void
    >({
      query: (variables) => ({ document: SubTemplatePreviewByContentIdParametersDocument, variables }),
    }),
    SubTemplateContentByParameters: build.query<
      SubTemplateContentByParametersQuery,
      SubTemplateContentByParametersQueryVariables
    >({
      query: (variables) => ({ document: SubTemplateContentByParametersDocument, variables }),
    }),
    UpdateSubTemplate: build.mutation<UpdateSubTemplateMutation, UpdateSubTemplateMutationVariables>({
      query: (variables) => ({ document: UpdateSubTemplateDocument, variables }),
    }),
    UpdateSubTemplateContent: build.mutation<
      UpdateSubTemplateContentMutation,
      UpdateSubTemplateContentMutationVariables
    >({
      query: (variables) => ({ document: UpdateSubTemplateContentDocument, variables }),
    }),
    ValidateSubTemplateInsertToContent: build.query<
      ValidateSubTemplateInsertToContentQuery,
      ValidateSubTemplateInsertToContentQueryVariables
    >({
      query: (variables) => ({ document: ValidateSubTemplateInsertToContentDocument, variables }),
    }),
    Workflows: build.query<WorkflowsQuery, WorkflowsQueryVariables | void>({
      query: (variables) => ({ document: WorkflowsDocument, variables }),
    }),
    CreateWorkflow: build.mutation<CreateWorkflowMutation, CreateWorkflowMutationVariables>({
      query: (variables) => ({ document: CreateWorkflowDocument, variables }),
    }),
    DeleteWorkflow: build.mutation<DeleteWorkflowMutation, DeleteWorkflowMutationVariables>({
      query: (variables) => ({ document: DeleteWorkflowDocument, variables }),
    }),
    WorkflowReferences: build.query<WorkflowReferencesQuery, WorkflowReferencesQueryVariables>({
      query: (variables) => ({ document: WorkflowReferencesDocument, variables }),
    }),
    Workflow: build.query<WorkflowQuery, WorkflowQueryVariables>({
      query: (variables) => ({ document: WorkflowDocument, variables }),
    }),
    UpdateWorkflow: build.mutation<UpdateWorkflowMutation, UpdateWorkflowMutationVariables>({
      query: (variables) => ({ document: UpdateWorkflowDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useEntryPointsQuery,
  useLazyEntryPointsQuery,
  useEntryPointStatisticsBasicQuery,
  useLazyEntryPointStatisticsBasicQuery,
  useEntryPointStatisticsBasic2Query,
  useLazyEntryPointStatisticsBasic2Query,
  useAssignEntryPointParametersMutation,
  useClearContentMutation,
  useCreateEntryPointMutation,
  useDeleteEntryPointMutation,
  useEntryPointQuery,
  useLazyEntryPointQuery,
  useEntryPointDetailQuery,
  useLazyEntryPointDetailQuery,
  useEntryPointCurrentTemplateIdQuery,
  useLazyEntryPointCurrentTemplateIdQuery,
  useEntryPointContentMatrixQuery,
  useLazyEntryPointContentMatrixQuery,
  useTemplatePreviewByContentIdParametersQuery,
  useLazyTemplatePreviewByContentIdParametersQuery,
  useTemplatePreviewByContentParametersQuery,
  useLazyTemplatePreviewByContentParametersQuery,
  useSendTemplatePreviewByContentIdParametersMutation,
  usePublishEntryPointMutation,
  useEntryPointStatisticsDetailQuery,
  useLazyEntryPointStatisticsDetailQuery,
  useTemplateContentByParametersQuery,
  useLazyTemplateContentByParametersQuery,
  useTemplateByIdQuery,
  useLazyTemplateByIdQuery,
  useUpdateTemplateMutation,
  useUpdateEntryPointMutation,
  useUpdateEntryPointContentMutation,
  useValidateEntryPointQuery,
  useLazyValidateEntryPointQuery,
  useImagesQuery,
  useLazyImagesQuery,
  useCreateImageMutation,
  useDeleteImageMutation,
  useImageReferencesQuery,
  useLazyImageReferencesQuery,
  useImageQuery,
  useLazyImageQuery,
  useUpdateImageMutation,
  useLinksQuery,
  useLazyLinksQuery,
  useAssignLinkParametersMutation,
  useCreateLinkMutation,
  useDeleteLinkMutation,
  useLinkReferencesQuery,
  useLazyLinkReferencesQuery,
  useLinkQuery,
  useLazyLinkQuery,
  useLinkPreviewByIdQuery,
  useLazyLinkPreviewByIdQuery,
  useLinkPreviewByContentParametersQuery,
  useLazyLinkPreviewByContentParametersQuery,
  useUpdateLinkMutation,
  useValidateLinkInsertToContentQuery,
  useLazyValidateLinkInsertToContentQuery,
  useMasterTemplatesQuery,
  useLazyMasterTemplatesQuery,
  useCreateMasterTemplateMutation,
  useDeleteMasterTemplateMutation,
  useMasterTemplateReferencesQuery,
  useLazyMasterTemplateReferencesQuery,
  useMasterTemplateQuery,
  useLazyMasterTemplateQuery,
  useUpdateMasterTemplateMutation,
  useParametersQuery,
  useLazyParametersQuery,
  useAllDeepParametersByTemplateIdQuery,
  useLazyAllDeepParametersByTemplateIdQuery,
  useAssignCodeBlockParametersMutation,
  useCreateParameterMutation,
  useDeleteParameterMutation,
  useParameterReferencesQuery,
  useLazyParameterReferencesQuery,
  useParameterQuery,
  useLazyParameterQuery,
  useUpdateParameterMutation,
  useCodeBlocksQuery,
  useLazyCodeBlocksQuery,
  useCreateCodeBlockMutation,
  useDeleteCodeBlockMutation,
  useCodeBlockReferencesQuery,
  useLazyCodeBlockReferencesQuery,
  useCodeBlockQuery,
  useLazyCodeBlockQuery,
  useCodeBlockDetailQuery,
  useLazyCodeBlockDetailQuery,
  useCodeBlockPreviewByIdQuery,
  useLazyCodeBlockPreviewByIdQuery,
  useCodeBlockPreviewByJsBodyQuery,
  useLazyCodeBlockPreviewByJsBodyQuery,
  useUpdateCodeBlockMutation,
  useUpdateCodeBlockContentMutation,
  useValidateCodeBlockInsertToContentQuery,
  useLazyValidateCodeBlockInsertToContentQuery,
  useSubTemplatesQuery,
  useLazySubTemplatesQuery,
  useUpdateSubTemplateConditionalUseMutation,
  useAssignSubTemplateParametersMutation,
  useCreateSubTemplateMutation,
  useDeleteSubTemplateMutation,
  useSubTemplateReferencesQuery,
  useLazySubTemplateReferencesQuery,
  useSubTemplateQuery,
  useLazySubTemplateQuery,
  useSubTemplateDetailQuery,
  useLazySubTemplateDetailQuery,
  useSubTemplatePreviewByContentIdParametersQuery,
  useLazySubTemplatePreviewByContentIdParametersQuery,
  useSubTemplateContentByParametersQuery,
  useLazySubTemplateContentByParametersQuery,
  useUpdateSubTemplateMutation,
  useUpdateSubTemplateContentMutation,
  useValidateSubTemplateInsertToContentQuery,
  useLazyValidateSubTemplateInsertToContentQuery,
  useWorkflowsQuery,
  useLazyWorkflowsQuery,
  useCreateWorkflowMutation,
  useDeleteWorkflowMutation,
  useWorkflowReferencesQuery,
  useLazyWorkflowReferencesQuery,
  useWorkflowQuery,
  useLazyWorkflowQuery,
  useUpdateWorkflowMutation,
} = injectedRtkApi;
