import { classNames } from 'primereact/utils';
import { useCallback, useEffect, useState } from 'react';
import {
  CodeBlock,
  EntryPointParameter,
  SubTemplate,
  useCodeBlocksQuery,
  useImagesQuery,
  useLazySubTemplateContentByParametersQuery,
  useLazySubTemplatePreviewByContentIdParametersQuery,
  useLinksQuery,
  useSubTemplateDetailQuery,
  useSubTemplatesQuery,
  useUpdateEntryPointContentMutation as useUpdateContentMutation,
  Link,
  Image,
  ParameterInput,
} from '../../api/generated';
import { ActionType } from '../../common/actionTypes';
import { BooleanAsString } from '../../common/booleanAsString';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import Page from '../../components/Page';
import useToast from '../../hooks/useToast';
import Editor from '../Editor';
import { ContentEditorProps, EntryPointEditorStore, MessageType } from '../Editor/editorTypes';
import useFetchContentPreview from '../Editor/hooks/useFetchContentPreview';
import { AppRoute } from '../../route/AppRoute';
import FaFileLinesIcon from '../Icons/FaFileLinesIcon';
import TopBar from '../Editor/components/TopBar';
import { getDataHolderNonDecisionParams, getDecisionParams, getSystemParams } from '../Editor/editorUtils';
import ContentEditorPreview from './components/ContentEditorPreview';
import ContentEditorSection from '../Editor/components/sections/ContentEditorSection';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useJoditEditorConnector } from '../Editor/components/jodit-editor/JoditEditorConnector';
import useValidateObjectInsert from '../../hooks/useValidateObjectInsert';
import EditorTreeItems from '../Editor/components/tree-items/EditorTreeItems';
import { AvailableObjectType } from '../Editor/components/tree-items/AvailableObjectType';
import ContentEditorEdit from './ContentEditor';

export default function SubTemplateEditor(props: ContentEditorProps) {
  const toast = useToast();

  const [action, setAction] = useState<ActionType>(ActionType.Edit);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showSaveSuccessful, setShowSaveSuccessful] = useState(false);

  const { data: entryPointTemplateData, isLoading: isLoadingEntryPointTemplate } = useSubTemplateDetailQuery({
    id: props.currentTemplateId?.toString() || '',
  });

  const { data: codeBlocks, isLoading: isLoadingCodeBlocks } = useCodeBlocksQuery({});
  const { data: subTemplates, isLoading: isLoadingSubTemplates } = useSubTemplatesQuery({});
  const { data: images, isLoading: isLoadingImages } = useImagesQuery({});
  const { data: links, isLoading: isLoadingLinks } = useLinksQuery({});
  const [getTemplate, { isFetching: isLoadingTemplate }] = useLazySubTemplateContentByParametersQuery({});
  const [getTemplatePreview, { isFetching: isLoadingPreview, isError: hasTemplatePreviewError }] =
    useLazySubTemplatePreviewByContentIdParametersQuery({});

  const [updateContent, { isLoading: isSaving }] = useUpdateContentMutation();
  const [contentId, setContentId] = useState<number | null>(null);
  const [contentTemplatePreview, setContentTemplatePreview] = useState<string | undefined>(undefined);
  const editorConnector = useJoditEditorConnector({ onChange: () => handleChange() });

  const [usedObjects, setUsedObjects] = useState<string[]>([]);

  const validateObjectInsert = useValidateObjectInsert();

  const [store, setStore] = useState<EntryPointEditorStore>({
    decisions: null,
    messageType: '',
    defaultLanguage: '',
    langsUsingDefault: [],
    specificLangs: [],
  });

  const detectUsedObjects = useCallback(() => {
    setUsedObjects(editorConnector.getVariables());
  }, [editorConnector]);

  const handleChange = useCallback(() => {
    setUnsavedChanges(true);
    detectUsedObjects();
  }, [setUnsavedChanges, detectUsedObjects]);

  const handleSave = useCallback(() => {
    updateContent({
      id: getContentIdOrThrow(contentId).toString(),
      input: {
        body: editorConnector.getValue(),
      },
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          setShowSaveSuccessful(false);
        }, 2000);
        setUnsavedChanges(false);
        setShowSaveSuccessful(true);
      })
      .catch(toast.error);
  }, [editorConnector, contentId, toast, updateContent]);

  const handlePreview = useCallback(
    async (contentId: number) => {
      await getTemplatePreview({
        input: {
          contentId: contentId,
          parameters: convertToParameters(store.decisions),
        },
      })
        .unwrap()
        .then((data) => {
          setContentTemplatePreview(data?.subTemplatePreviewByContentIdParameters || undefined);
        })
        .catch((ex) => {
          console.log(ex);
        });
    },
    [getTemplatePreview, store.decisions],
  );

  const handleUpdateStore = useCallback(
    (data: Partial<EntryPointEditorStore>, ask: boolean) => {
      const updateStore = (hideConfirmDialog?: () => void) => {
        hideConfirmDialog && hideConfirmDialog();
        setStore((prev) => ({ ...prev, ...data }));
      };

      if (unsavedChanges && ask) {
        const { hide } = confirmDialog({
          message: 'You have unsaved changes. If you continue, your changes will be lost. Do you want to proceed?',
          header: 'Unsaved Changes',
          icon: 'fa-light fa-triangle-exclamation fa-2xl',
          accept: () => updateStore(hide),
        });
      } else {
        updateStore();
      }
    },
    [unsavedChanges],
  );

  /** Refetch data when decision params changes */
  const refetchTemplate = useCallback(async () => {
    if (entryPointTemplateData && entryPointTemplateData.subTemplateById) {
      await getTemplate({
        input: {
          subTemplateId: entryPointTemplateData.subTemplateById.subTemplateId,
          parameters: convertToParameters(store.decisions),
        },
      })
        .unwrap()
        .then((result) => {
          const contentTemplate = result.subTemplateContentByParameters?.body || '';
          const templateContentId = result.subTemplateContentByParameters?.contentId;

          setContentId(templateContentId || null);
          editorConnector.setValue(contentTemplate);
          setUnsavedChanges(false);
          detectUsedObjects();

          templateContentId && handlePreview(templateContentId);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryPointTemplateData, getTemplate, store.decisions]);

  /** Parse Languages */
  useEffect(() => {
    const langsUsingDefault = entryPointTemplateData?.subTemplateById?.languagesUsingDefaultLanguage || ([] as any);
    handleUpdateStore(
      {
        defaultLanguage: entryPointTemplateData?.subTemplateById?.defaultLanguage || '',
        langsUsingDefault: langsUsingDefault,
      },
      false,
    );
  }, [entryPointTemplateData, handleUpdateStore]);

  useEffect(() => {
    const decisions = new Map<number, string>();

    /** Parse decision params */
    props.contentParameters.forEach((p: any) => {
      if ((p && p.isDecision) || p?.parameter?.name.startsWith('Sys')) {
        const firstItem = p.parameter?.enumItems ? p.parameter?.enumItems[0].key : BooleanAsString.True;
        decisions.set(p.parameterId, firstItem);
      }
    });

    /** Handle languages using default lang, select first available */
    const langs = props.contentParameters.find((p) => p?.parameter?.name === 'SysLanguage')?.parameter;
    const specificContentLangs =
      langs?.enumItems
        ?.filter((lang) => lang.key !== store.defaultLanguage)
        .filter((lang) => !store.langsUsingDefault.includes(lang.key))
        .map((entry) => entry.key) || [];

    if (specificContentLangs.length === 0 && store.defaultLanguage) {
      decisions.set(1, store.defaultLanguage);
    } else if (specificContentLangs.length > 0) {
      decisions.set(1, specificContentLangs[0]);
    }

    handleUpdateStore({ decisions: decisions }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contentParameters, store.defaultLanguage, store.langsUsingDefault]);

  /** Parse Notification Type - SysMessageType */
  useEffect(() => {
    const messageType = props.contentParameters.find((p) => p?.parameter?.name === 'SysMessageType')?.parameter;
    const firstItem = messageType?.enumItems ? messageType.enumItems[0].key : '';
    handleUpdateStore({ messageType: firstItem }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contentParameters]);

  useEffect(() => {
    refetchTemplate();
  }, [refetchTemplate, store.decisions]);

  /** Fetch template preview */
  useFetchContentPreview(action, () => contentId && handlePreview(getContentIdOrThrow(contentId)));

  const getContentIdOrThrow = (contentId: number | null | undefined) => {
    if (contentId) {
      return contentId;
    }

    throw new Error('Missing contentId');
  };

  const isLoading =
    props.isLoading ||
    isLoadingEntryPointTemplate ||
    isLoadingCodeBlocks ||
    isLoadingSubTemplates ||
    isLoadingImages ||
    isLoadingLinks;

  return (
    <>
      <Page
        className={classNames(props.className, 'content-editor')}
        windowTitle={`Edit ${props.content.name}`}
        topBar={
          <TopBar
            previousPath={AppRoute.SubTemplates}
            title={'Sub-template'}
            icon={<FaFileLinesIcon />}
            contentName={props.content.name}
            previewVisible={true}
            isLoading={isLoading}
            setAction={setAction}
          />
        }
      >
        <MainLayoutWithSidebar
          sidebarPosition="left"
          isLoading={isLoading}
          sidebar={
            <>
              <EditorTreeItems
                parameters={getDataHolderNonDecisionParams(props.contentParameters) as EntryPointParameter[]}
                codeBlocks={codeBlocks?.allCodeBlocks as CodeBlock[]}
                subTemplates={subTemplates?.allSubTemplates as SubTemplate[]}
                images={images?.allImages as Image[]}
                links={links?.allLinks as Link[]}
                usedObjects={usedObjects}
                onObjectSelect={(object) => {
                  editorConnector.insertVariable(object.value);
                  validateObjectInsert(object, contentId || 0);
                }}
                path={props.path}
                enabledTypes={
                  store.messageType !== MessageType.Email
                    ? [AvailableObjectType.CodeBlock, AvailableObjectType.Parameter, AvailableObjectType.SubTemplate]
                    : undefined
                }
              />
            </>
          }
        >
          <ConfirmDialog />
          <Editor
            path={props.path}
            action={action}
            decisionParams={getDecisionParams(props.contentParameters)}
            systemParams={getSystemParams(props.contentParameters)}
            store={store}
            onChange={(data) => handleUpdateStore(data, true)}
            onSave={handleSave}
            unsavedChanges={unsavedChanges}
            templateId={props.currentTemplateId?.toString() || ''}
          >
            <ContentEditorSection
              action={action}
              messageType={store.messageType as MessageType}
              contentId={contentId || 0}
              params={convertToParameters(store.decisions)}
              unsavedChanges={unsavedChanges}
              saveSuccessful={showSaveSuccessful}
              isLoading={isLoadingTemplate}
              isSaving={isSaving}
              isDisabled={false}
              minimalHeight={370}
              onSave={handleSave}
              onClear={() => {
                editorConnector.setValue(null);
                setUnsavedChanges(true);
              }}
              edit={
                <ContentEditorEdit
                  contentId={contentId}
                  messageType={store.messageType}
                  editorConnector={editorConnector}
                />
              }
              preview={
                <ContentEditorPreview
                  messageType={store.messageType}
                  contentTemplatePreview={contentTemplatePreview}
                  isContentEmpty={!contentTemplatePreview}
                  isLoadingPreview={isLoadingPreview}
                  hasTemplatePreviewError={hasTemplatePreviewError}
                />
              }
            />
          </Editor>
        </MainLayoutWithSidebar>
      </Page>
    </>
  );
}

function convertToParameters(decisions: Map<number, string> | null | undefined): ParameterInput[] {
  if (!decisions) {
    return [];
  }

  return Array.from(decisions.entries())
    .filter(([_, value]) => value !== 'defaultValue')
    .map(([key, value]) => ({ parameterId: key, value }));
}
