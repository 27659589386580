import { JoditEditorConnector } from '../../Editor/components/jodit-editor/JoditEditorConnector';
import JoditEditor from '../../Editor/components/jodit-editor/JoditEditor';
import { MessageType } from '../../Editor/editorTypes';
import { useState } from 'react';
import { ToggleButton } from 'primereact/togglebutton';
import MonacoEditor from '../../MonacoEditor';

type Props = {
  messageType: MessageType;
  subjectEditorConnector: JoditEditorConnector;
  bodyEditorConnector: JoditEditorConnector;
  onEditorFocus: (connector: JoditEditorConnector) => void;
  isDisabled?: boolean;
};

export default function ContentEditorEdit(props: Props) {
  const [isSubjectSourceView, setIsSubjectSourceView] = useState(false);
  const [isBodySourceView, setIsBodySourceView] = useState(false);

  const toggleSubjectSourceView = () => {
    setIsSubjectSourceView(!isSubjectSourceView);
  };

  const toggleBodySourceView = () => {
    setIsBodySourceView(!isBodySourceView);
  };

  const isSubjectDisabled = props.isDisabled || props.messageType === MessageType.Sms;

  return (
    <>
      <div className="mb-3">
        <div className="field">
          <div className="d-flex justify-content-between align-items-center">
            <div className="title">Subject</div>
            {!isSubjectDisabled && (
              <ToggleButton
                className="p-button-mini"
                onLabel="Source View"
                offLabel="Editor View"
                onIcon="pi pi-code"
                offIcon="pi pi-pencil"
                checked={isSubjectSourceView}
                onChange={toggleSubjectSourceView}
                style={{ marginLeft: '10px' }}
              />
            )}
          </div>
          <div className="mt-2"></div>
          {isSubjectSourceView ? (
            <MonacoEditor
              language="html"
              readOnly
              customHeight="40px"
              value={props.subjectEditorConnector.getValue()}
            />
          ) : (
            <JoditEditor
              disabled={isSubjectDisabled}
              isOneLine
              isWithoutStyles
              withoutToolbar
              placeholder="This subject is empty and has no value."
              editorConnector={props.subjectEditorConnector}
              onFocus={() => props.onEditorFocus(props.subjectEditorConnector)}
            />
          )}
        </div>
      </div>
      <div className="field">
        <div className="d-flex justify-content-between align-items-center">
          <div className="title">Body</div>
          {!props.isDisabled && (
            <ToggleButton
              className="p-button-mini"
              onLabel="Source View"
              offLabel="Editor View"
              onIcon="pi pi-code"
              offIcon="pi pi-pencil"
              checked={isBodySourceView}
              onChange={toggleBodySourceView}
              style={{ marginLeft: '10px' }}
            />
          )}
        </div>
        <div className="mt-2"></div>
        {isBodySourceView ? (
          <MonacoEditor language="html" readOnly customHeight="500px" value={props.bodyEditorConnector.getValue()} />
        ) : (
          <JoditEditor
            disabled={props.isDisabled}
            placeholder="This content is empty and has no value."
            editorConnector={props.bodyEditorConnector}
            onFocus={() => props.onEditorFocus(props.bodyEditorConnector)}
            isWithoutStyles={props.messageType !== MessageType.Email}
            withoutToolbar={props.messageType !== MessageType.Email}
          />
        )}
      </div>
    </>
  );
}
