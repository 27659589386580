import { useState } from 'react';
import { ToggleButton } from 'primereact/togglebutton';
import MonacoEditor from '../MonacoEditor';
import JoditEditor from '../Editor/components/jodit-editor/JoditEditor';
import { MessageType } from '../Editor/editorTypes';
import { JoditEditorConnector } from '../Editor/components/jodit-editor/JoditEditorConnector';

type Props = {
  contentId: number | null;
  messageType: string;
  editorConnector: JoditEditorConnector;
  isDisabled?: boolean;
};

export default function ContentEditorEdit(props: Props) {
  const [isBodySourceView, setIsBodySourceView] = useState(false);

  const toggleBodySourceView = () => {
    setIsBodySourceView(!isBodySourceView);
  };

  return (
    <>
      <div className="field">
        <div className="d-flex justify-content-between align-items-center">
          {!props.isDisabled && (
            <ToggleButton
              className="p-button-mini ml-auto"
              onLabel="Source View"
              offLabel="Editor View"
              onIcon="pi pi-code"
              offIcon="pi pi-pencil"
              checked={isBodySourceView}
              onChange={toggleBodySourceView}
              style={{ marginLeft: '10px' }}
            />
          )}
        </div>
        <div className="mt-2"></div>
        {isBodySourceView ? (
          <MonacoEditor language="html" readOnly customHeight="500px" value={props.editorConnector.getValue()} />
        ) : (
          <JoditEditor
            key={props.contentId}
            placeholder="This content is empty and has no value."
            editorConnector={props.editorConnector}
            disabled={props.isDisabled}
            isWithoutStyles={props.messageType !== MessageType.Email}
            withoutToolbar={props.messageType !== MessageType.Email}
          />
        )}
      </div>
    </>
  );
}
